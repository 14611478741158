import React, { useState } from 'react';
import { Box, Chip, Button } from '@mui/material';

interface ChipItem {
   value: string;
   [key: string]: any; // For any additional properties
}

interface ChipListProps {
   /** Array of items to display as chips */
   items: ChipItem[];
   /** Callback function when a chip is deleted, receives the index of the deleted item */
   onDelete: (index: number) => void;
   /** Prefix for the key prop of each chip */
   prefix: string;
}

const MAX_ITEMS = 6;

const ChipList: React.FC<ChipListProps> = ({ items, onDelete, prefix }) => {
   const [showAll, setShowAll] = useState(false);

   const displayItems = showAll ? items : items.slice(0, MAX_ITEMS);
   const hasMore = items.length > MAX_ITEMS;

   return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
         {displayItems.map((field, i) => (
            <Chip
               key={`${prefix}_${field?.value}`}
               sx={{ marginRight: '2px' }}
               size="small"
               label={field?.value}
               variant="filled"
               onDelete={() => onDelete(i)}
            />
         ))}
         {hasMore && !showAll && (
            <Button
               size="small"
               onClick={() => setShowAll(true)}
               sx={{ fontSize: '0.8125rem', padding: '2px 8px' }}
            >
               Show all ({items.length})...
            </Button>
         )}
         {hasMore && showAll && (
            <Button
               size="small"
               onClick={() => setShowAll(false)}
               sx={{ fontSize: '0.8125rem', padding: '2px 8px' }}
            >
               Show less
            </Button>
         )}
      </Box>
   );
};

export default ChipList;