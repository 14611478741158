import * as React from "react";
import {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {PotentialsList} from "../components/PotentialsList";
import {ConsumerPagePath} from "../shopdata/consumers/ConsumerPage";
import {Potential} from "../../../Queries";

export const PotentialsPage: FC<any> = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();
   const onPotentialSelect = (potential: Potential) => {
      navigate(ConsumerPagePath(brandHouseId, shopId, potential.consumerId))
   }

   return <Page>
      <PageHeader title={"Potentials"}/>
      <PageContent fullHeight>
         <PotentialsList shopId={shopId} onPotentialSelect={onPotentialSelect} stateKey={"mainpot_" + shopId}/>
      </PageContent>
   </Page>
}