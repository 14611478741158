export const generateMonthOptions = (numYears: number, field: string) => {
   const options = [];
   const now = new Date();

   // Loop over the number of months in the given period
   for (let i = 0; i < numYears * 12; i++) {
      // Create a date representing the first day of the month, going backwards in time
      const monthDate = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const startDate = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1);
      const endDate = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 1);

      // Format the month label (e.g., "2025 February")
      const label = `${startDate.getFullYear()} ${startDate.toLocaleString('default', { month: 'long' })}`;

      options.push({
         label,
         filter: {
            AND: [
               { _operators: { [field]: { gt: startDate.toISOString() } } },
               { _operators: { [field]: { lte: endDate.toISOString() } } }
            ]
         }
      });
   }

   return options;
};
