import {BodyText, TitleText} from "../../../layout/Typography";
import React, {FC} from "react";
import {Box, LinearProgress} from "@mui/material";
import {ConsumerPagePath} from "../shopdata/consumers/ConsumerPage";
import {LinkTo} from "../../../components/LinkTo";
import {usePanelConsumerByIdQuery, usePanelExternConsumerByIdQuery} from "../../../Queries";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin, CurrentUserIsShop} from "../../../atoms/CurrentUser";

type ConsumerPanelProps = {
   consumerId: string,
   hideDetailsLink?: boolean
   hideTitle?: boolean
}

export const ConsumerPanel: FC<ConsumerPanelProps> = ({consumerId, hideDetailsLink, hideTitle}) => {
   const {data: consumerData} = usePanelConsumerByIdQuery({
      variables: {
         consumerId: consumerId
      }
   });
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   if (!consumerData) {
      return <LinearProgress/>
   }
   const consumer = consumerData.ConsumerById!;
   const tradeIns = consumerData.TradeInMany!

   let url = consumer.tradeInAppUrl;
   if(url.includes('?')) {
      url += '&preview=true'
   } else {
      url += '?preview=true'
   }
   return <>
      {!hideTitle && <TitleText type={"h1"}>Contact information</TitleText>}
      <BodyText type={"body1"}>
         {consumer.firstName + ' ' + consumer.lastName}
      </BodyText>
      <BodyText type={"body1"}>
         {consumer.add1}
      </BodyText>
      <BodyText type={"body1"}>
         {consumer.add2}
      </BodyText>
      <BodyText type={"body1"}>
         {consumer.zipCode} {consumer.city}
      </BodyText>
      <BodyText type={"body1"}>
         {consumer.country}
      </BodyText>
      <BodyText type={"body1"}>
         {consumer.mail}
      </BodyText>
      <BodyText type={"body1"}>
         {consumer.phone}
      </BodyText>
      <BodyText type={"body1"}>
         <a rel="noreferrer" target="_blank"
            href={url}>TradeIn Page</a>
      </BodyText>
      {!hideDetailsLink &&
         <BodyText type={"body1"}>
            <LinkTo to={ConsumerPagePath(consumer.shop?.brandHouseId, consumer.shopId, consumer._id)}>
               View details
            </LinkTo>
         </BodyText>
      }
      <TitleText style={{marginTop: '10px'}} type={"h2"}>Statistics</TitleText>
      <BodyText type={"body1"}>
         Trade-in's: {tradeIns?.length}
      </BodyText>
      {consumer.externalId && <ExternOrders externConsumerId={consumer.externalId!}
                                            externUrl={consumer.externUrl!}
                                            currency={consumer.shop?.currency || '??'}/>}
      {isAdministrator && <Box sx={{color: '#c0c0c0', marginTop: '20px'}}>
         Accesstoken: {consumer.accessToken}
      </Box>}
   </>
}

const ExternOrders: FC<{ externConsumerId: string, currency: string, externUrl: string }> = ({
                                                                                                externConsumerId,
                                                                                                currency,
                                                                                                externUrl
                                                                                             }) => {
   const {data} = usePanelExternConsumerByIdQuery({
      variables: {
         consumerId: externConsumerId
      }
   })
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);


   if (!data) {
      return <LinearProgress/>
   }

   const ordersText = `${data.ExternOrders2Many?.length}`
   return <BodyText type={"body1"}>
      Number of orders: <a href={externUrl} target={!isBrandHouse ? "_blank" : "_top"}
                           rel={"noreferrer"}>{ordersText}</a>
   </BodyText>
}