import * as React from "react";

export type FlexFilterValue = {
   filterId: string
   values: string[]
   requireAll?: boolean
}

export interface FlexListColumn<T> {
   id: string
   label: string
   field?: string
   width?: number
   align?: 'left' | 'center' | 'right' | 'justify'
   get?: (row: T) => string | null | undefined | any | any[] | number
   render?: (data: any, row?: T, context?: any) => React.ReactNode
   ellipsis?: boolean
}

export interface FlexListOptions {
   idField: string
   itemHeight: number
   hideHeader: boolean
   maxRows: number
   height: number
   searchLabel: string
}


export type FlexTab = {
   id: string
   label: string
   filters: FlexFilterValue[]
   showCount?: boolean
   columns?: string[]
}

/**
 * Props for the flex list
 */
export interface FlexListProps<T> {
   globalId: string
   columns: FlexListColumn<T>[]
   options?: Partial<FlexListOptions>
   filters?: FlexFilter<any>[]
   sorts?: FlexSort<any>[]
   initialTab?: string
   tabs?: FlexTab[]
   itemsPerPage: number
   onRequestData: (search: string | undefined, sort: string | undefined, page: number, filter?: any) => Promise<FlexListRequestData<T>>
   onTabChange?: (tab: string) => void
   onSelectRow?: (row: T, newWindow?: boolean, update?: (row: any) => void) => void
   context?: any
   selections?: boolean
   commands?: FlexCommand<T>[]
}

export interface FlexCommand<T> {
   id: string
   title: string
   icon: any
   execute?: (rows: T[]) => Promise<void>
   children?: FlexCommand<T>[]
}

export interface FlexListRequestData<T> {
   data: T[]
   totalCount: number
}

export type FlexFilter<F> = {
   id: string
   label: string
   options: FlexFilterOption<F>[]
}

export type FlexFilterOption<F> = {
   id?: string
   label: string
   subtext?: string
   filter: F
}

export type FlexSort<F> = {
   id: string
   label: string
   value: F
}


export function buildGraphQLFilter(filters?: FlexFilter<any>[], values?: FlexFilterValue[]) {
   let totalFilter: any[] = [];
   filters?.forEach(filter => {
      const selected = values?.find(value => value.filterId === filter.id);
      let effectiveFilterAnd: any[] = [];
      let effectiveFilterOr: any[] = [];
      if (selected) {
         for (const selectedElement of selected.values) {
            const option = filter.options.find(option => option.label === selectedElement || option.id === selectedElement)
            if (option?.filter) {
               if (selected.requireAll) {
                  effectiveFilterAnd.push(option?.filter);
               } else {
                  effectiveFilterOr.push(option?.filter);
               }
            }
         }
      }
      if (effectiveFilterOr.length > 0) {
         totalFilter.push({
            OR: effectiveFilterOr
         });
      }
      if (effectiveFilterAnd.length > 0) {
         totalFilter.push({
            AND: effectiveFilterAnd
         });
      }
   });
   if (totalFilter.length > 0) {
      return {
         AND: totalFilter
      }
   } else {
      return {}
   }
}


