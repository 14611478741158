import * as React from "react";
import {FC} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {
   BrandItemStateEnum,
   FlexBrandItemFragment, useBrandItemImageAutoAdjustMutation, useBrandItemsPageChangeStateMutation,
   useLogisticsBrandItemInfoQuery,
   useLogisticsBrandItemRetouchMutation,
   useLogisticsBrandItemsPagePushMutation
} from "../../Queries";
import {FlexCommand} from "../../components/flexlist/FlexTypes";
import {EditAttributes, EditAttributesOutlined, PictureAsPdf, Storefront} from "@mui/icons-material";
import {BrandItemsList} from "../brand/BrandItemsList";
import {Dialogs} from "../../DialogProvider";
import {SavingUI} from "../../SavingProvider";
import {ParamBrandItem, ParamLogisticsPartner} from "../../Parameters";
import {Box, Drawer, LinearProgress} from "@mui/material";
import {BrandItemPageCore} from "../brand/brandItem/BrandItemPage";
import EditIcon from "@mui/icons-material/Edit";

export const LogisticsBrandItemsPath = (logisticsPartnerId: string) => {
   return "/logisticspartners/" + logisticsPartnerId + "/branditems"
}

export const LogisticsBrandItemsPageWrapper = () => {
   const {logisticsPartnerId, brandItemId} = useParams<ParamLogisticsPartner & ParamBrandItem>();
   const navigate = useNavigate();

   const onCloseDrawer = () => {
      navigate(LogisticsBrandItemsPath(logisticsPartnerId))
   }

   const drawerOpen = !!brandItemId
   return (
      <>
         <LogisticsBrandItemsPage/>
         {brandItemId && (
            <Drawer anchor="right" open={drawerOpen} onClose={onCloseDrawer}
                    PaperProps={{style: {width: '70%', backgroundColor: '#f8f8f8'}}}>
               <Box sx={{padding: '20px'}}>
                  <Outlet/>
               </Box>
            </Drawer>
         )}
      </>
   );

}

export const LogisticsBrandItemPagePath = (logisticsPartnerId: string, brandItemId: string) => {
   return "/logisticspartners/" + logisticsPartnerId + "/branditems/" + brandItemId
}

export const LogisticsItemPage = () => {
   const {brandItemId} = useParams<ParamBrandItem>()
   const {data} = useLogisticsBrandItemInfoQuery({
      variables: {
         brandItemId
      }
   })
   if(!data) {
      return <LinearProgress/>
   }
   return <BrandItemPageCore brandItemId={brandItemId} brandHouseId={data.BrandItem?.brandHouseId} inDrawer={true}/>
}

export const LogisticsBrandItemsPage: FC<any> = () => {
   const {logisticsPartnerId} = useParams<ParamLogisticsPartner>();

   const navigate = useNavigate();
   const [retouchImages] = useLogisticsBrandItemRetouchMutation()
   const [publishItem] = useLogisticsBrandItemsPagePushMutation();
   const [changeState] = useBrandItemsPageChangeStateMutation()
   const [adjustImages] = useBrandItemImageAutoAdjustMutation()

   const onSelectRow = async (row: FlexBrandItemFragment) => {
      navigate(LogisticsBrandItemPagePath(logisticsPartnerId, row._id))
   }

   const onChangeState = async (rows: FlexBrandItemFragment[], state: BrandItemStateEnum) => {
      await SavingUI.process(async () => {
         for (const row of rows) {
            await changeState({
               variables: {
                  brandHouseId: row.brandHouseId,
                  id: row._id,
                  state: state
               }
            })
         }
      }, 'Done', 'Changing state, please wait')
   }


   const commands: FlexCommand<FlexBrandItemFragment>[] = [{
      id: 'publish',
      title: 'Publish',
      icon: Storefront,
      execute: async rows => {
         for (const row of rows) {
            await publishItem({
               variables: {
                  brandItemId: row._id,
                  active: true
               }
            })
         }
      }
   }, {
      id: 'unpublish',
      title: 'Unpublish',
      icon: Storefront,
      execute: async rows => {
         for (const row of rows) {
            await publishItem({
               variables: {
                  brandItemId: row._id,
                  active: false
               }
            })
         }
      }
   }, {
      id: "retouch",
      title: "Retouch Images",
      execute: async rows => {
         Dialogs.confirm({
            title: 'Start retouch of images from selected products?',
            subtitle: 'You will not be able to undo this operation.'
         }, async () => {
            await SavingUI.process(async () => {
               await retouchImages({
                  variables: {
                     ids: rows.map(row => row._id)
                  }
               })
            }, 'Retouch started', 'Initializing retouch, please wait')
         })
      },
      icon: PictureAsPdf
   }, {
      id: 'changeState',
      title: 'Change state',
      icon: EditAttributesOutlined,
      children: Object.keys(BrandItemStateEnum).map(key => ({
         execute: async rows => onChangeState(rows, key as BrandItemStateEnum),
         icon: EditAttributes,
         title: key,
         id: key
      }))
   }, {
      id: 'adjustImages',
      title: 'Auto adjust images',
      icon: EditIcon,
      execute: async rows => {
         await SavingUI.process(async () => {
            for (const row of rows) {
               await adjustImages({
                  variables: {
                     brandItemId: row._id
                  }
               })
            }
         }, 'Adjust complete', 'Adjusting master images, please wait')

      },
   }]

   return <Page>
      <PageHeader title={"Brand items"}/>
      <PageContent fullHeight>
         <BrandItemsList onSelectItem={onSelectRow} listId={"logisticsBrandItemList"}
                         columnOptions={{
                            brand: true,
                            locationTs: true
                         }}
                         commands={commands}
         />
      </PageContent>
   </Page>
}