import writeXlsxFile from 'write-excel-file'
import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {LocalizationEntry, OrderExportFragment, OrderExportLineFragment, useExportOrdersQuery} from "../../../Queries";

type ExportLine = {
   itemNr: number
   item: OrderExportLineFragment
   order: OrderExportFragment
}

export const ExportToExcel = async (language: string, currency: string, data: ExportLine[]) => {
   // @ts-ignore
   const schema: any = [{
      column: "ITEMNR",
      type: Number,
      value: (object: ExportLine) => object.item?.item?.brandItem?.itemNr
   }, {
      column: "ITEMSKU",
      type: String,
      value: (object: ExportLine) => object.item?.item?.brandItem?.resolved?.itemSku || ''
   }, {
      column: "ITEMNAME",
      type: String,
      value: (object: ExportLine) => lookup(object.item?.item?.brandItem?.resolved?.nameLocalized, language, 'DA')
   }, {
      column: "ORDERDATE",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: ExportLine) => new Date(object.order.orderDate)
   }, {
      column: "ORDERNAME",
      type: String,
      value: (object: ExportLine) => object.order.orderName || ''
   }, {
      column: "SALESPRICE",
      type: Number,
      value: (object: ExportLine) => object.item?.salesPricePreTax || 0
   }, {
      column: "RETURNED",
      type: String,
      value: (object: ExportLine) => object.item?.returned ? 'YES' : 'NO'
   }, {
      column: "RETURNEDREASON",
      type: String,
      value: (object: ExportLine) => object.item?.returnedReason || ''
   }, {
      column: "TRADEINCOST",
      type: Number,
      value: (object: ExportLine) => object.item?.item?.brandItem?.tradeInItem?.value
   }];

   await writeXlsxFile(data, {
      schema,
      fileName: 'orders-' + Date.now() + '.xlsx'
   })
}


type BrandItemExportProps = {
   language: string
   currency: string
   ids: string[]
}

export const OrdersExport = ({language, currency, ids}: BrandItemExportProps) => {
   const [open, setOpen] = useState<boolean>(true);
   const {data} = useExportOrdersQuery({
      variables: {
         orderIds: ids,
         currency: currency
      }
   });

   useEffect(() => {
      if (data?.OrderPagination) {
         setOpen(true);
         (async function () {
            const exportLines: ExportLine[] = []
            const orders = ((data.OrderPagination?.items || []) as OrderExportFragment[])
            for (const tradeIn of orders) {
               const items = ((tradeIn.lines || []) as OrderExportLineFragment[])
               let itemNr: number = 1
               for (const item of items) {
                  exportLines.push({
                     item,
                     order: tradeIn,
                     itemNr: itemNr++
                  })
               }
            }
            await ExportToExcel(language, currency, exportLines);
            setOpen(false);
         })();
      }
   }, [currency, data, ids, language])

   return <Dialog open={open}>
      <DialogContent>
         <CircularProgress size={100}/>
         Preparing data, please wait...
      </DialogContent>
   </Dialog>
}


const lookup = (localizations?: (LocalizationEntry | null)[] | null, language?: string, defaultLanguage?: string): string | undefined => {
   const internalFind = (lang?: string) => {
      return localizations?.find(loc => compare(loc?.locale, lang))
   }
   const matchingLocalization = internalFind(language);
   if (!matchingLocalization) {
      if (defaultLanguage) {
         return internalFind(defaultLanguage)?.value;
      } else {
         return localizations?.[0]?.value
      }
   }
   return matchingLocalization.value
}

const compare = (s1?: string, s2?: string) => {
   if (s1 && s2) {
      return s1.localeCompare(s2, 'en', {sensitivity: 'base'}) === 0
   } else {
      return false;
   }
}
