import {FC, useState} from "react";
import Page from "../../../../layout/Page";
import PageHeader from "../../../../layout/PageHeader";
import PageContent from "../../../../layout/PageContent";
import PageGroup from "../../../../layout/PageGroup";
import {useParams} from "react-router-dom";
import useConsumer from "./hooks/useConsumer";
import {Dialog, DialogContent, LinearProgress} from "@mui/material";
import {ConsumerPanel} from "../../components/ConsumerPanel";
import {ConsumerTradeIns} from "./views/ConsumerTradeIns";
import {TitleText} from "../../../../layout/Typography";
import {ConsumerEvents} from "../../../brand/ConsumerEvents";
import Button from "@mui/material/Button";
import {PotentialsList} from "../../components/PotentialsList";
import {CircularPointsPanel} from "../../components/CircularPointsPanel";
import {CreateDetails, PotentialAddDialog} from "./PotentialAddDialog";
import {Potential, useConsumerPageFetchPotentialsMutation} from "../../../../Queries";
import {SavingUI} from "../../../../SavingProvider";

export const ConsumerPagePath = (brandHouseId: string, shopId: string, consumerId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/consumers/" + consumerId
}

export const ConsumerPage: FC<any> = () => {
   const {
      shopId,
      consumerId
   } = useParams<{ brandHouseId: string, shopId: string, consumerId: string }>();
   const consumer = useConsumer(consumerId)
   const [addPotential, setAddPotential] = useState<boolean>(false)
   const [fetchPotentials] = useConsumerPageFetchPotentialsMutation()

   if (!consumer) {
      return <LinearProgress/>
   }

   const onPotentialSelect = (potential: Potential) => {

   }

   const onCreateTradeIn = (details: CreateDetails) => {
      setAddPotential(false)
   }

   const onFetchPotentials = async () => {
      await SavingUI.process(async () => {
         await fetchPotentials({
            variables: {
               shopId,
               consumerId
            }
         })
      }, 'Fetching')
   }

   const onAddPotential = () => {
      setAddPotential(true)
   }

   return <Page>
      <PageHeader title={consumer.firstName + ' ' + consumer.lastName} onBack={true}/>

      <PageGroup>
         <PageContent>
            <TitleText type={"h2"}>Activity</TitleText>
            <ConsumerEvents consumerId={consumerId} options={{
               height: 200,
               hideHeader: true
            }}/>
         </PageContent>
         <PageContent>
            <ConsumerPanel consumerId={consumerId} hideDetailsLink={true}/>
         </PageContent>
      </PageGroup>

      <PageContent>
         <TitleText sx={{marginBottom: '20px'}} type={"h2"}>Trade-ins</TitleText>
         <ConsumerTradeIns consumerId={consumerId}/>
      </PageContent>
      <PageContent commands={[
         <Button key={"fetchPotentials"} onClick={onFetchPotentials}>Fetch potentials</Button>,
         <Button key={"addPotential"} onClick={onAddPotential}>Add</Button>
      ]}>
         <TitleText type={"h2"}>Potential trade-ins</TitleText>
         <PotentialsList shopId={shopId} onPotentialSelect={onPotentialSelect} consumerId={consumerId}
                         hideTradeIns={true}/>
      </PageContent>
      <PageContent>
         <TitleText type={"h2"}>Circular Points</TitleText>
         <CircularPointsPanel consumerId={consumerId}/>
      </PageContent>
      {addPotential && <Dialog open={addPotential} onClose={() => setAddPotential(false)} fullWidth maxWidth={"md"}>
         <DialogContent>
            <PotentialAddDialog consumerId={consumerId} shopId={shopId} onCancel={() => setAddPotential(false)}
                                onCreateTradeIn={onCreateTradeIn}/>
         </DialogContent>
      </Dialog>}
   </Page>
}
