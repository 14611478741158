import {BrandProductsList} from "../BrandProductList";
import {useNavigate, useParams} from "react-router-dom";
import {ParamBrandHouse} from "../../../Parameters";
import PageHeader from "../../../layout/PageHeader";
import * as React from "react";
import Page from "../../../layout/Page";
import PageContent from "../../../layout/PageContent";
import {MasterDataEditorCreatePath, MasterDataEditorPath} from "./MasterDataEditPage";
import Button from "@mui/material/Button";

export const MasterDataPage = () => {
   const [groupId, setGroupId] = React.useState<string | undefined>(undefined)
   const {brandHouseId} = useParams<ParamBrandHouse>()
   const navigate = useNavigate();

   const onCreate = () => {
      if (groupId) {
         navigate(MasterDataEditorCreatePath(brandHouseId, groupId));
      }
   }

   const onTabChange = (groupId: string) => {
      setGroupId(groupId)
   }

   return <Page>
      <PageHeader title={"Master Data"}>
         <Button variant={"contained"} onClick={onCreate}>
            Create
         </Button>
      </PageHeader>
      <PageContent>
         <BrandProductsList onTabChange={onTabChange} brandHouseId={brandHouseId} inDialog={false}
                            onProductSelect={(product) => {
                               navigate(MasterDataEditorPath(brandHouseId, product._id))
                            }}/>
      </PageContent>
   </Page>
}