import {Box, Button, Grid, IconButton, Tab, Tabs} from "@mui/material";
import {FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import * as React from "react";
import {useState} from "react";
import {FormInputLocalized} from "../../shop/wholesale/form/FormInputLocalized";
import {Languages} from "../../settings/languages/Languages";
import PageContent from "../../../layout/PageContent";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {BodyText, TitleText} from "../../../layout/Typography";
import {FormInputSelect} from "../../shop/wholesale/form/FormInputSelect";
import {FormInputFree} from "../../shop/wholesale/form/FormInputFree";
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DeleteIcon from "@mui/icons-material/Delete";
import ChipList from "./components/ChipList";
import {EnumBrandProductImagePerspective, EnumBrandProductImageType, PricingInput} from "../../../Queries";
import {ImageEditor} from "./components/ImageEditor";
import {usePrompt} from "../../../components/useBlocker";
import {FormInputPrice} from "../../shop/wholesale/form/FormInputPrice";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";

type MasterDataEditorProps = {
   brandHouseId: string

   data: MasterEditorData
   onSave: (data: MasterEditorData) => Promise<void>
   locales: string[]
   currencies: string[]

   info: MasterInfoData
}

export interface MasterInfoData {
   createdAt: string
   updatedAt: string
}

export interface MasterEditorData {
   _id: string
   externalId: string
   groupId: string

   name: LocalizedField[]
   description: LocalizedField[]
   type: LocalizedField[]
   vendor: string

   state: MasterItemState

   collections: MasterItemChip[]
   tags: MasterItemChip[]

   variants: MasterEditVariant[]

   disableRRPListPrice: boolean
}

export interface MasterItemChip {
   value: string
}

export enum MasterItemState {
   ACTIVE = 'ACTIVE',
   DEACTIVATED = "DEACTIVATED",
   DELETED = 'DELETED'
}

export interface MasterEditVariant {
   _id: string
   externalId: string

   sku: string
   deactivated: boolean
   color: LocalizedField[]

   countryOrigin: string
   commodityCode: string

   sizes: MasterEditSize[]

   prices: PricingInput[]

   images: MasterEditImage[]
}

export interface MasterEditSize {
   _id: string
   externalId: string
   sku: string
   ean: string
   deleted: boolean
   size: string
}

export interface MasterEditImage {
   _id: string
   externalId: string
   deleted: boolean
   url: string
   type: EnumBrandProductImageType
   perspective: EnumBrandProductImagePerspective
}

export interface LocalizedField {
   locale: string
   value: string
}

export const MasterDataEditor = ({brandHouseId, currencies, locales, data, info, onSave}: MasterDataEditorProps) => {
   const [formKey] = useState(0);
   const [locale, setLocale] = useState<string>(locales[0])

   const form = useForm<MasterEditorData & { newTag: string, newCol: string }>({
      defaultValues: {
         ...data,
         newTag: "",
         newCol: ""
      }
   });

   const {setValue, getValues, handleSubmit, control, formState: {isDirty}} = form;

   const {fields: collections, append: collectionAppend, remove: collectionRemove} = useFieldArray({
      control,
      name: 'collections'
   })

   const {fields: tags, append: tagAppend, remove: tagRemove} = useFieldArray({
      control,
      name: 'tags'
   })

   usePrompt('Remember to save changes', isDirty)

   return (
      <Grid container>
         <FormProvider {...form} key={formKey}>
            <form id={"masterDataForm"} onSubmit={handleSubmit(onSave)} style={{width: '100%'}}>
               <Grid container sx={{marginBottom: '10px'}} spacing={1}>
                  <Grid item {...({
                     xs: 12,
                     md: 7
                  })}>
                     <PageContent>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                           <Tabs value={locale} onChange={(e, val) => {
                              setLocale(val);
                           }}>
                              {locales.map(locale => {
                                 const language = Languages.get(locale)
                                 return <Tab key={'brandItemEditor_tab_' + locale} value={locale}
                                             label={language?.name}/>
                              })}

                           </Tabs>
                        </Box>

                        <FormInputLocalized field={"name"} locale={locale} label={"Name"}/>
                        <FormInputLocalized
                           field={"description"}
                           label={"Description"}
                           html={true}
                           locale={locale}
                        />
                        <FormInputLocalized field={"type"} locale={locale} label={"Product type"}
                                            sx={{marginTop: '5px'}}/>
                        <FormInputText name={"vendor"} label={"Vendor"}/>
                     </PageContent>
                  </Grid>
                  <Grid item {...({
                     xs: 12,
                     md: 5
                  })}>
                     <PageContent>
                        <Box sx={{marginBottom: '15px'}}>
                           <BodyText type={"body1"}>Status</BodyText>
                           <FormInputSelect name={"state"} label={"Item state"}
                                            options={Object.values(MasterItemState).map(key => ({
                                               id: key,
                                               name: key
                                            }))}/>

                        </Box>

                        <Box sx={{marginBottom: '20px'}}>
                           <BodyText sx={{marginBottom: '-10px'}} type={"body1"}>General settings</BodyText>
                           <FormInputCheckbox name={"disableRRPListPrice"} label={"Disallow RRP list pricing for this product"}/>
                        </Box>

                        <Box sx={{marginBottom: '20px'}}>
                           <BodyText type={"body1"}>Collections</BodyText>
                           <FormInputFree label={"Add collectiopn"} name={"newCol"}
                                          options={collections.map(field => field.value)}
                                          onKeyDown={(e) => {
                                             if (e.key === 'Enter') {
                                                e.preventDefault();
                                             }
                                          }}
                                          onKeyUp={(e) => {
                                             if (e.key === 'Enter') {
                                                let newTag = getValues('newCol');
                                                if (!collections.find(field => field.value === newTag)) {
                                                   collectionAppend({
                                                      value: newTag
                                                   });
                                                }
                                                setValue('newCol', '', {
                                                   shouldTouch: false
                                                });
                                             }
                                          }}
                           />
                           <ChipList
                              items={collections}
                              onDelete={collectionRemove}
                              prefix="collection"
                           />
                        </Box>

                        <Box>
                           <BodyText type={"body1"}>Tags</BodyText>
                           <FormInputFree label={"Add tag"} name={"newTag"} options={tags.map(field => field.value)}
                                          onKeyDown={(e) => {
                                             if (e.key === 'Enter') {
                                                e.preventDefault();
                                             }
                                          }}
                                          onKeyUp={(e) => {
                                             if (e.key === 'Enter') {
                                                let newTag = getValues('newTag');
                                                if (!collections.find(field => field.value === newTag)) {
                                                   tagAppend({
                                                      value: newTag
                                                   });
                                                }
                                                setValue('newTag', '', {
                                                   shouldTouch: false
                                                });
                                             }
                                          }}
                           />
                           <ChipList
                              items={tags}
                              onDelete={tagRemove}
                              prefix="tags"
                           />
                        </Box>
                     </PageContent>
                  </Grid>
               </Grid>
               <Grid>
                  <VariantEditor currencies={currencies} locale={locale} brandHouseId={brandHouseId} locales={locales}/>
               </Grid>
            </form>
         </FormProvider>
      </Grid>
   )
}

const Accordion = styled((props: AccordionProps) => (
   <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
   <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
      {...props}
   />
))(({theme}) => ({
   backgroundColor:
      theme.palette.mode === 'dark'
         ? 'rgba(255, 255, 255, .05)'
         : 'rgba(0, 0, 0, .03)',
   flexDirection: 'row-reverse',
   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
   },
   '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
   },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
   padding: theme.spacing(2),
   borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


type VariantEditorProps = {
   locale: string
   brandHouseId: string
   locales: string[]
   currencies: string[]
}

const VariantEditor = ({brandHouseId, currencies, locale, locales}: VariantEditorProps) => {
   const [expanded, setExpanded] = React.useState<number | undefined>(0);

   const formContext = useFormContext<MasterEditorData>()
   const {control} = formContext;

   const {fields: variants, append: variantAppend, remove: variantRemove} = useFieldArray({
      control,
      name: 'variants'
   })

   const handleExpand = (i: number) => () => {
      setExpanded(expanded === i ? undefined : i);
   }

   return <Box>
      {variants.map((variant, i) => {
         const color = variant.color.find(field => field.locale === locale)?.value;
         return <Accordion expanded={expanded === i} onChange={handleExpand(i)}>
            <AccordionSummary
               aria-controls={`panel${i}d-content`}
               id={`panel${i}d-header`}
            >
               <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
               }}>
                  <div>Variant {i + 1} - {color}</div>
                  <IconButton
                     size="small"
                     onClick={(e) => {
                        e.stopPropagation();
                        variantRemove(i);
                     }}
                  >
                     <DeleteIcon/>
                  </IconButton>
               </Box>
            </AccordionSummary>
            <AccordionDetails>
               <Box key={'variant_' + i}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} md={6}>
                        <Box>
                           <TitleText type="h2">Settings</TitleText>
                           <FormInputLocalized field={`variants.${i}.color`} locale={locale}
                                               label={"Color (" + locale + ")"}/>
                           <FormInputText name={`variants.${i}.sku`} label={"SKU"}/>
                           <FormInputText name={`variants.${i}.countryOrigin`} label={"Country of Origin"}/>
                           <FormInputText name={`variants.${i}.commodityCode`} label={"Commodity Code"}/>
                        </Box>
                     </Grid>
                     <Grid item xs={12} md={6}>
                        <Box>
                           <TitleText type="h2">Prices</TitleText>
                           {currencies.map((currency, priceIndex) => (
                              <FormInputPrice
                                 key={`price-${currency}-${priceIndex}`}
                                 name={`variants.${i}.prices.${priceIndex}.value`}
                                 label={currency}
                              />
                           ))}
                        </Box>
                     </Grid>
                  </Grid>
                  <Box>
                     <ImageEditor variant={i} brandHouseId={brandHouseId}/>
                  </Box>
                  <Box>
                     <SizeEditor variant={i}/>
                  </Box>

               </Box>
            </AccordionDetails>
         </Accordion>
      })}
      <Box>
         <Button
            key={"addVariant"}
            size={"small"}
            onClick={() => {
               variantAppend({
                  _id: '',
                  externalId: '',
                  sku: '',
                  deactivated: false,
                  color: locales.map(loc => ({
                     locale: loc,
                     value: ''
                  })),
                  countryOrigin: '',
                  commodityCode: '',
                  sizes: [],
                  images: [],
                  prices: currencies.map(currency => ({
                     currency: currency,
                     value: 0
                  }))
               });
               setExpanded(variants.length);
            }}
         >
            Add variant
         </Button>
      </Box>
   </Box>
}

const VISIBLE_SIZES = 4

export const SizeEditor = ({variant}: { variant: number }) => {
   const [showAll, setShowAll] = useState(false);
   const formContext = useFormContext<MasterEditorData>();
   const {control} = formContext;

   const {fields: sizes, append: sizeAppend, remove: sizeRemove} = useFieldArray({
      control,
      name: `variants.${variant}.sizes`
   });

   const handleAddSize = () => {
      sizeAppend({
         _id: '',
         externalId: '',
         sku: '',
         ean: '',
         deleted: false,
         size: ''
      });
   };

   const visibleSizes = showAll ? sizes : sizes.slice(0, VISIBLE_SIZES);

   return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
         <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box>
               <TitleText type="h2">Sizes</TitleText>
            </Box>
         </Box>

         {visibleSizes.map((size, i) => (
            <Box
               key={'size_' + variant + '_' + i}
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
               }}
            >
               <Box sx={{flex: 1}}>
                  <FormInputText
                     name={`variants.${variant}.sizes.${i}.size`}
                     label="Size"
                  />
               </Box>
               <Box sx={{flex: 1}}>
                  <FormInputText
                     name={`variants.${variant}.sizes.${i}.sku`}
                     label="SKU"
                  />
               </Box>
               <Box sx={{flex: 1}}>
                  <FormInputText
                     name={`variants.${variant}.sizes.${i}.ean`}
                     label="EAN"
                  />
               </Box>
               <IconButton
                  size="small"
                  onClick={() => sizeRemove(i)}
                  sx={{mt: -1}}
               >
                  <DeleteIcon/>
               </IconButton>
            </Box>
         ))}

         <Box>
            {sizes.length > VISIBLE_SIZES && (
               <Button
                  color={"secondary"}
                  onClick={() => setShowAll(!showAll)}
                  size={"small"}
               >
                  {showAll ? 'Show less' : `View ${sizes.length - VISIBLE_SIZES} more`}
               </Button>
            )}

            <Button
               size="small"
               onClick={handleAddSize}
            >
               Add Size
            </Button>
         </Box>
      </Box>
   );
};
