import writeXlsxFile from 'write-excel-file'
import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {
   TradeInExportFragment,
   TradeInExportItemFragment,
   useExportTradeInsConsumerLazyQuery,
   useExportTradeInsQuery
} from "../../Queries";

type ExportLine = {
   ordersBefore: number
   ordersAfter: number
   tradeinsBefore: number
   items: TradeInExportItemFragment[]
   tradeIn: TradeInExportFragment
}

const numberFormat = "#,##0.00"

export const ExportToExcel = async (language: string, currency: string, data: ExportLine[]) => {
   // @ts-ignore
   const schema: any = [{
      column: "TRADEINNR",
      type: Number,
      value: (object: ExportLine) => object.tradeIn.tradeInNr,
      width: 14
   }, {
      column: "TRADEINDATE",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: ExportLine) => new Date(object.tradeIn.dateTime)
   }, {
      column: "RECEIVED",
      type: Boolean,
      value: (object: ExportLine) => object.tradeIn.registered || false
   }, {
      column: "CONSUMERMAIL",
      type: String,
      value: (object: ExportLine) => object.tradeIn.consumer?.mail || 'Unknown@mail',
      width: 20
   }, {
      column: "SHOP",
      type: String,
      value: (object: ExportLine) => object.tradeIn.shop?.name || 'Unknown shop',
      width: 15
   }, {
      column: "ITEMS",
      type: Number,
      value: (object: ExportLine) => object.items.length,
      format: numberFormat,
      width: 7
   }, {
      column: "USED",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: ExportLine) => object.tradeIn.vouchers?.[0]?.statistics?.usageDate ? new Date(object.tradeIn.vouchers[0].statistics.usageDate) : undefined,
      width: 10
   }, {
      column: "VALUE",
      type: Number,
      value: (object: ExportLine) => object.tradeIn.vouchers?.[0]?.statistics?.reward || 0,
      format: numberFormat,
      width: 8
   }, {
      column: "SPENDING",
      type: Number,
      value: (object: ExportLine) => object.tradeIn.vouchers?.[0]?.statistics?.spending || 0,
      format: numberFormat,
      width: 9
   }, {
      column: "SMASHFACTOR",
      type: Number,
      value: (object: ExportLine) => object.tradeIn.vouchers?.[0]?.statistics?.smashFactor,
      format: numberFormat,
      width: 10
   }, {
      column: "RESALES",
      type: Number,
      value: (object: ExportLine) => object.items.reduce((acc, item) => acc + (item.brandItem?.orderline?.salesPricePreTax || 0), 0),
      format: numberFormat,
      width: 8
   }, {
      column: "ORDERSBEFORE",
      type: Number,
      value: (object: ExportLine) => object.ordersBefore || 0,
      format: numberFormat,
      width: 13.7
   }, {
      column: "ORDERSAFTER",
      type: Number,
      value: (object: ExportLine) => object.ordersAfter || 0,
      format: numberFormat,
      width: 12.4
   }, {
      column: "TRADEINSBEFORE",
      type: Number,
      value: (object: ExportLine) => object.tradeinsBefore || 0,
      format: numberFormat,
      width: 15.4
   }];

   await writeXlsxFile(data, {
      schema,
      fileName: 'tradeins-' + Date.now() + '.xlsx'
   })
}


type BrandItemExportProps = {
   language: string
   currency: string
   ids: string[]
}

export const TradeInExport = ({language, currency, ids}: BrandItemExportProps) => {
   const [open, setOpen] = useState<boolean>(true);
   const {data} = useExportTradeInsQuery({
      variables: {
         tradeIns: ids,
         currency: currency,
      }
   });
   const [fetchConsumerStats] = useExportTradeInsConsumerLazyQuery()

   useEffect(() => {
      if (data?.TradeIns) {
         setOpen(true);
         (async function () {
            const exportLines: ExportLine[] = []
            const tradeIns = ((data.TradeIns || []) as TradeInExportFragment[])
            for (const tradeIn of tradeIns) {
               const exportLine: ExportLine = {
                  items: [],
                  tradeIn,
                  ordersBefore: 0,
                  tradeinsBefore: 0,
                  ordersAfter: 0
               }

               const {data: stats} = await fetchConsumerStats({
                  variables: {
                     dateTime: tradeIn.dateTime,
                     consumerId: tradeIn.consumer!._id,
                     now: new Date()
                  }
               });

               if (stats) {
                  exportLine.ordersBefore = stats.ConsumerById?.statisticsBefore?.orders || 0
                  exportLine.ordersAfter = (stats.ConsumerById?.statisticsAfter?.orders || 0) - exportLine.ordersBefore
                  exportLine.tradeinsBefore = stats.ConsumerById?.statisticsBefore?.tradeIns || 0
               }

               const items = ((tradeIn.items || []) as TradeInExportItemFragment[])
               for (const item of items) {
                  exportLine.items.push(item)
               }
               exportLines.push(exportLine)
            }
            await ExportToExcel(language, currency, exportLines);
            setOpen(false);
         })();
      }
   }, [currency, data, fetchConsumerStats, ids, language])

   return <Dialog open={open}>
      <DialogContent>
         <CircularProgress size={100}/>
         Preparing data, please wait...
      </DialogContent>
   </Dialog>
}
