import {v4 as uuidv4} from 'uuid';
import {
   LocalizedField,
   MasterEditImage,
   MasterEditorData,
   MasterEditSize,
   MasterEditVariant,
   MasterItemState
} from './MasterDataEditor';
import {
   BrandProductImageSynchronizeInput,
   BrandProductSizeSynchronizeInput,
   BrandProductSynchronizeInput,
   BrandProductVariantSynchronizeInput,
   ImagePerspectiveEnum,
   ImageTypeEnum,
   LocalizationEntryInput
} from '../../../Queries';

// Utility to remove empty values from an object
const removeEmptyValues = <T extends Record<string, any>>(obj: T): Partial<T> => {
   const result: Partial<T> = {};

   for (const [key, value] of Object.entries(obj)) {
      if (value === null || value === undefined || value === '') {
         continue;
      }

      if (Array.isArray(value)) {
         if (value.length > 0) {
            result[key as keyof T] = value.map(item =>
               typeof item === 'object' ? removeEmptyValues(item) : item
            ).filter(Boolean) as T[keyof T];
         }
      } else if (typeof value === 'object') {
         const cleaned = removeEmptyValues(value);
         if (Object.keys(cleaned).length > 0) {
            result[key as keyof T] = cleaned as T[keyof T];
         }
      } else {
         result[key as keyof T] = value;
      }
   }

   return result;
};

// Convert LocalizedField[] to LocalizationEntryInput[]
const convertLocalizedFields = (fields: LocalizedField[]): LocalizationEntryInput[] => {
   return fields
      .filter(field => field.value !== '')
      .map(field => ({
         locale: field.locale,
         value: field.value
      }));
};

// Convert MasterEditSize to BrandProductSizeSynchronizeInput
const convertSize = (size: MasterEditSize, locales: string[]): BrandProductSizeSynchronizeInput => {
   const input: BrandProductSizeSynchronizeInput = {
      externalId: size.externalId || uuidv4(),
      sku: size.sku,
      ean: size.ean,
      deleted: size.deleted,
      // Convert the single size value into localized entries for all locales
      sizeLocalized: locales.map(locale => ({
         locale,
         value: size.size
      })).filter(entry => entry.value !== '')
   };

   return removeEmptyValues(input) as BrandProductSizeSynchronizeInput;
};

// Convert MasterEditImage to ProductImageSynchronizeInput
const convertImage = (image: MasterEditImage): BrandProductImageSynchronizeInput => {
   const input: BrandProductImageSynchronizeInput = {
      externalId: image.externalId || uuidv4(),
      sourceUrl: image.url,
      perspective: image.perspective as any as ImagePerspectiveEnum,
      type: image.type as any as ImageTypeEnum
   };

   return removeEmptyValues(input) as BrandProductImageSynchronizeInput;
};

// Convert MasterEditVariant to BrandProductVariantSynchronizeInput
const convertVariant = (variant: MasterEditVariant, locales: string[]): BrandProductVariantSynchronizeInput => {
   const input: BrandProductVariantSynchronizeInput = {
      externalId: variant.externalId || uuidv4(),
      sku: variant.sku,
      deactivated: variant.deactivated,
      colorLocalized: convertLocalizedFields(variant.color),
      countryOrigin: variant.countryOrigin,
      commodityCode: variant.commodityCode,
      sizes: variant.sizes.map(size => convertSize(size, locales)),
      images: variant.images.map(convertImage),
      rrp: variant.prices.filter(price => price.value !== null && price.value !== undefined)
   };

   return removeEmptyValues(input) as BrandProductVariantSynchronizeInput;
};

export const convertToSyncInput = (data: MasterEditorData, locales: string[]): BrandProductSynchronizeInput => {
   const input: BrandProductSynchronizeInput = {
      externalId: data.externalId || uuidv4(),
      nameLocalized: convertLocalizedFields(data.name),
      descriptionLocalized: convertLocalizedFields(data.description),
      typeLocalized: convertLocalizedFields(data.type),
      vendor: data.vendor,
      disabled: data.state === MasterItemState.DEACTIVATED,
      deleted: data.state === MasterItemState.DELETED,
      collections: data.collections.map(c => c.value),
      tags: data.tags.map(t => t.value),
      variants: data.variants.map(variant => convertVariant(variant, locales)),
      groupId: data.groupId,
      disableRRPListPrice: data.disableRRPListPrice,
   };

   return removeEmptyValues(input) as BrandProductSynchronizeInput;
};
