import * as React from "react";
import {FC} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Alert, Checkbox, CheckboxProps, FormControlLabel} from "@mui/material";

export type FormInputCheckboxProps = Omit<CheckboxProps, 'name'> & {
   name: string;
   label: string;
   errorMessage?: string;
   /**
    * @deprecated now using formcontext
    */
   control?: any;
};

export const FormInputCheckbox: FC<FormInputCheckboxProps> = ({
                                                                 name,
                                                                 label,
                                                                 disabled,
                                                                 title,
                                                                 ...rest
                                                              }) => {
   const methods = useFormContext();
   const {
      control,
      formState: {errors},
   } = methods;

   return (
      <Controller
         name={name}
         control={control}
         render={({field: {onChange, value}, fieldState: {error}}) => (
            <div>
               <FormControlLabel
                  control={
                     <Checkbox
                        id={name}
                        name={name}
                        size="small"
                        {...rest}
                        onChange={onChange}
                        checked={value === true}
                        indeterminate={value === undefined}
                        disabled={disabled}
                     />
                  }
                  label={label}
                  title={title}
               />
               {error && (
                  <Alert variant="filled" severity="error">
                     {errors[name]?.message}
                  </Alert>
               )}
            </div>
         )}
      />
   );
};