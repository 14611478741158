import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {FC, useCallback} from "react";
import {Alert} from '@mui/material';
import {StringHelper} from "../../utility/StringHelper";
import {ConsumerPagePath} from "../shop/shopdata/consumers/ConsumerPage";
import {
   ConsumerEventMaildata,
   FilterFindManyConsumerEventInput,
   SortFindManyConsumerEventInput,
   useConsumerEventPaginationLazyQuery,
} from "../../Queries";
import {FlexList} from "../../components/flexlist/FlexList";
import {FlexFilter, FlexListColumn, FlexListOptions, FlexSort} from "../../components/flexlist/FlexTypes";
import dayjs from "dayjs";

type ConsumerEventsProps = {
   consumerId?: string
   options?: Partial<FlexListOptions>
}

const LABEL_CONSUMER = 'Consumer';

const PAGE_SIZE = 20;

export const ConsumerEvents: FC<ConsumerEventsProps> = ({consumerId, options}) => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();
   const [fetchMore] = useConsumerEventPaginationLazyQuery({
      fetchPolicy: "no-cache"
   });

   let columns: FlexListColumn<any>[] = [{
      id: 'dateTime',
      field: 'dateTime',
      label: 'Date',
      get: (row) => {
         return new Date(row.dateTime).getTime()
      },
      render: (data) => {
         return dayjs(data).format('DD-MM-YYYY HH:mm');
      }
   }, {
      id: 'consumerId',
      field: 'consumerId',
      label: LABEL_CONSUMER,
      get: (row) => {
         if (row.consumer && row.consumer.firstName) {
            return row.consumer.firstName + ' ' + row.consumer.lastName
         } else if (row.consumer?.mail) {
            return row.consumer.mail
         } else if (row.consumer?.selfCreated) {
            return 'Wholesale'
         } else {
            return 'Unknown'
         }
      }
   }, {
      id: 'event',
      field: 'event',
      label: 'Event',
      get: (row) => {
         if (row.subEvent) {
            return row.subEvent + ' ' + row.event
         } else {
            return row.event;
         }
      },
      render: (event, row) => {
         return StringHelper.titleCase(event);
      }
   }, {
      id: 'eventDetails',
      label: 'Event details',
      get: (row) => {
         return row.maildata
      },
      render: (maildata?: ConsumerEventMaildata) => {
         if (maildata) {
            if (maildata.clicked) {
               return <Alert severity={"success"}>Link clicked</Alert>
            } else if (maildata.opened) {
               return <Alert severity={"success"}>Mail read</Alert>
            } else if (maildata.delivered) {
               return <Alert severity={"info"}>Delivered</Alert>
            } else if (maildata.bounced) {
               return <Alert style={{paddingTop: 0, paddingBottom: 0}} severity={"warning"}>Mail bounced</Alert>
            } else if (maildata.spam) {
               return <Alert severity={"error"}>Spam reported</Alert>
            } else {
               return <Alert severity={"info"}>Sent</Alert>
            }
         } else {
            return <div style={{height: '36px'}}>&nbsp;</div>
         }
      }
   }]

   if (consumerId) {
      columns = columns.filter(col => {
         return col.label !== LABEL_CONSUMER
      })
   }


   const onSelectEvent = (row: any) => {
      navigate(ConsumerPagePath(brandHouseId, shopId, row.consumer._id))
   }

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyConsumerEventInput) || SortFindManyConsumerEventInput._ID_DESC,
            perPage: PAGE_SIZE,
            search: search,
            filter: {
               consumerId: consumerId,
               shopId: shopId,
               ...filter
            }
         }
      });
      
      return {
         data: data?.ConsumerEventPagination?.items || [],
         totalCount: data?.ConsumerEventPagination?.count || 0
      }
   }, [fetchMore, consumerId, shopId]);

   const sortSpec: FlexSort<SortFindManyConsumerEventInput>[] = [{
      id: 'CreatedAsc',
      label: 'Created (oldest first)',
      value: SortFindManyConsumerEventInput._ID_ASC
   }, {
      id: 'CreatedDesc',
      label: 'Created (newest first)',
      value: SortFindManyConsumerEventInput._ID_DESC
   }]


   const filterSpec: FlexFilter<FilterFindManyConsumerEventInput>[] = [{
      id: 'event',
      label: 'Event',
      options: [{
         label: 'Login',
         filter: {
            event: 'login'
         }
      }, {
         label: 'Mail',
         filter: {
            event: "mail"
         }
      }, {
         label: 'Trade in',
         filter: {
            event: "tradein"
         }
      }]
   }];

   return <FlexList
      globalId={"consumerEvents"}
      selections={false}
      columns={columns}
      onRequestData={onRequestData}
      onSelectRow={onSelectEvent}
      itemsPerPage={PAGE_SIZE}
      options={options}
      filters={filterSpec}
      sorts={sortSpec}
   />
}
