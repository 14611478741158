import {Pricing, PricingInput} from "../../Queries";

type ToCurrenciesProps = {
   currencies: string[]
   data?: (Pricing | null)[] | null
}

export const toCurrencies = ({currencies, data}: ToCurrenciesProps) => {
   const result: PricingInput[] = [];
   for (const currency of currencies) {
      const value = data?.find(cur => cur?.currency === currency);
      result.push({
         currency: currency,
         value: value?.value as number
      })
   }
   return result;
}
