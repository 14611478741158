import {useNavigate, useParams} from "react-router";
import {ParamBrandHouse, ParamTradeInId} from "../../../Parameters";
import PageHeader from "../../../layout/PageHeader";
import Button from "@mui/material/Button";
import {BodyText, Link, TitleText} from "../../../layout/Typography";
import {DateFormat} from "../../../utility/DateFormat";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, {useCallback, useEffect} from "react";
import {
   BrandItemStateEnum,
   BrandTradeInDocument,
   BrandTradeInItemsDocument,
   BrandTradeInSizeFragment,
   EnumTradeInItemRejected,
   EnumTradeInPayoutType,
   LocalizationEntry,
   TradeInItem,
   TradeInItemRejectReasonEnum,
   TradeInUpdateInput,
   useBrandTradeInCreateItemMutation,
   useBrandTradeInDeleteMutation,
   useBrandTradeInItemsQuery,
   useBrandTradeInItemsRemoveMutation,
   useBrandTradeInItemUpdateMutation,
   useBrandTradeInQuery,
   useBrandTradeInSettingsQuery,
   useBrandTradeInSynchronizeMutation,
   useBrandTradeInUpdateMutation,
   useBrandWholeSaleCreateMutation
} from "../../../Queries";
import Page from "../../../layout/Page";
import {
   Box,
   Chip,
   Dialog,
   DialogContent,
   DialogTitle,
   Divider,
   FormControlLabel,
   LinearProgress,
   ListItemIcon,
   Menu,
   MenuItem,
   Switch
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PageContent from "../../../layout/PageContent";
import {Thumbnails} from "../../../components/Thumbnails";
import CreateIcon from "@mui/icons-material/Create";
import {ConsumerPanel} from "../../shop/components/ConsumerPanel";
import {getTradeInStatus} from "../../shop/components/TradeInList";
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../../../atoms/CurrentUser";
import makeStyles from "@mui/styles/makeStyles";
import {Condition} from "../../shop/components/Condition";
import {Dialogs} from "../../../DialogProvider";
import {BrandTradeInsListPagePath} from "./BrandTradeInsListPage";
import {StockItemEditPagePath} from "../../shop/stockitems/edit/StockItemEditPage";
import {BrandItemPagePath} from "../brandItem/BrandItemPage";
import {SavingUI} from "../../../SavingProvider";
import {BrandTradeInDialog} from "./BrandTradeInDialog";
import {TradeInPagePath} from "../../shop/tradeins/TradeInPage";
import {PotentialsList} from "../../shop/components/PotentialsList";
import Checkbox from "@mui/material/Checkbox";
import LanguageIcon from "@mui/icons-material/Language";
import {ObjectHistory} from "../../components/ObjectHistory";
import {VoucherPagePath} from "../vouchers/VoucherPage";

export const BrandTradeInPagePath = (brandHouseId: string, tradeInId: string) => {
   return BrandTradeInsListPagePath(brandHouseId) + '/' + tradeInId
}

export const BrandTradeInPage = () => {
   const {brandHouseId, tradeInId} = useParams<ParamBrandHouse & ParamTradeInId>();

   const [tradeInItem, setTradeInItem] = React.useState<TradeInItem | undefined>();
   const [adding, setAdding] = React.useState<boolean>(false);
   const [selected, setSelected] = React.useState<string[]>([])

   const [createPotential] = useBrandWholeSaleCreateMutation()

   const isBrandLogin = useRecoilValue(CurrentUserIsShop)
   const classes = useStyles();
   const navigate = useNavigate();

   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);

   const {data} = useBrandTradeInQuery({
      variables: {
         tradeInId
      }
   })
   const {data: itemsQuery} = useBrandTradeInItemsQuery({
      variables: {
         tradeInId
      }
   })
   const {data: settings} = useBrandTradeInSettingsQuery({
      variables: {
         brandHouseID: brandHouseId
      }
   })

   const [_updateItem] = useBrandTradeInItemUpdateMutation()
   const [_updateTradeIn] = useBrandTradeInUpdateMutation()
   const [removeItems] = useBrandTradeInItemsRemoveMutation()
   const [deleteTradeIn] = useBrandTradeInDeleteMutation()
   const [_createBrandItem] = useBrandTradeInCreateItemMutation()
   const [sync] = useBrandTradeInSynchronizeMutation()

   const onToggleReceived = async () => {
      Dialogs.confirm({
         title: 'TradeIn Package Ready?',
         subtitle: 'Press "Yes" to confirm that\n* The package has been received.\n* All needed modifications to the trade-in has been done\n* That we can send a "Thank you" email to the customer.\n'
      }, async () => {
         await onRegisterPackage(!tradeIn?.registered)
      })
   }

   const isShopLogin = useCallback(() => data && settings && !settings.BrandSettings?.useBrandItems, [data, settings])

   useEffect(() => {
      if (isShopLogin()) {
         navigate(TradeInPagePath(brandHouseId, data?.TradeInById?.shopId, tradeInId), {
            replace: true
         })
      }
   }, [brandHouseId, data, isShopLogin, navigate, settings, tradeInId])

   useEffect(() => {
      const thisTradeIn = data?.TradeInById;
      if (thisTradeIn && !isBrandLogin && !thisTradeIn.registered) {
         if (isShopLogin()) {
            return;
         }
         Dialogs.info({
            title: 'TradeIn Not Yet Received or Processed',
            subtitle: 'Please click the "TradeIn Ready" button when the trade-in has been edited to match the received content'
         }, () => {
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isShopLogin, data, isBrandLogin])

   const tradeIn = data?.TradeInById
   if (!tradeIn || !itemsQuery) {
      return <LinearProgress/>
   }
   const items = itemsQuery.TradeInItems3
   const shop = data?.TradeInById?.shop!
   const consumer = data?.TradeInById?.consumer

   const refetchTradeIn = {
      query: BrandTradeInDocument,
      variables: {
         tradeInId
      }
   };
   const refetchTradeInItems = {
      query: BrandTradeInItemsDocument,
      variables: {
         tradeInId
      }
   };
   const refetchAll = [refetchTradeIn, refetchTradeInItems]

   const updateItem = async (id: string, item: Partial<TradeInItem>) => {
      await _updateItem({
         variables: {
            id: id,
            record: {
               ...item
            }
         },
         refetchQueries: refetchAll
      });
   }

   const updateTradeIn = async (id: string, item: Partial<TradeInUpdateInput>, itemIds?: string[]) => {
      await _updateTradeIn({
         variables: {
            id: id,
            input: {
               ...item
            },
            items: itemIds
         },
         refetchQueries: refetchAll
      });
   }

   const onConditionChange = async (item: TradeInItem, condition: number | null) => {
      await SavingUI.process(async () => {
         await updateItem(item._id, {
            condition: condition || undefined
         })
      }, 'Condition changed', 'Please wait')
   }

   const onRejectItem = async (item: TradeInItem, reject: boolean) => {
      await SavingUI.process(async () => {
         await updateItem(item._id, {
            rejected: EnumTradeInItemRejected.OTHER
         })
      }, reject ? 'Item rejected' : 'Rejecting undone', 'Please wait')
   }

   const onRegisterPackage = async (registered: boolean) => {
      await SavingUI.process(async () => {
         await sync({
            variables: {
               input: {
                  _id: tradeInId,
                  registered: true
               }
            }
         });
      }, 'Tradein registered', 'Please wait')
   }

   const onRemoveItem = () => {
      Dialogs.confirm({
         title: 'Confirm reject of item?',
         subtitle: 'The item will be rejected from this tradein.'
      }, async () => {
         await removeItems({
            variables: {
               tradeInId: tradeInId,
               items: selected,
               reject: TradeInItemRejectReasonEnum.OTHER
            },
            refetchQueries: refetchAll
         });
         setSelected([])
      })
   }

   const onDelete = () => {
      Dialogs.confirm({
         title: 'Are you sure you wish the delete this tradein?',
         subtitle: 'This cannot be undone. The traded in item will be released and the consumer will be allowed to trade it in again. Also the voucher for this tradein will be deleted'
      }, async () => {
         await deleteTradeIn({
            variables: {
               tradeInId
            },
            refetchQueries: refetchAll
         });
         navigate(BrandTradeInsListPagePath(brandHouseId));
      })
   }

   const createBrandItem = async (item: TradeInItem) => {
      const sizeId = item.brandProductVariant?.sizes?.find(size => {
         return size?.sizeLocalized?.find(loc => loc?.value === item?.size)
      })?._id || item.brandProductSizeId;

      let subtitle = sizeId ? 'SIZE: ' + item.size + '\nCOLOR: ' + item.color : 'No size found, an empty item will be created';
      Dialogs.confirm({
         title: 'Confirm the following',
         subtitle: subtitle
      }, async () => {
         await SavingUI.process(async () => {
            const payload = {
               brandHouseId,
               input: {
                  brandProductSizeId: sizeId,
                  condition: item.condition!,
                  itemState: BrandItemStateEnum.NONE,
                  tradeInItemId: item._id,
                  images: item.brandProductVariant?.images?.map(img => ({
                     brandProductImageId: img!._id
                  })).slice(0, 2)
               }
            };
            await _createBrandItem({
               variables: payload,
               refetchQueries: refetchAll
            })
         }, 'Item created', 'Please wait')
      })
   }

   const onAccept = (item: TradeInItem) => async () => {
      await createBrandItem(item);
   }

   const createCategory = (shopId: string, categoryId: string) => async () => {
      if (!consumer) {
         return;
      }
      await SavingUI.process(async () => {
         const {data} = await createPotential({
            variables: {
               categoryId: categoryId,
               consumerId: consumer._id
            },
         });

         if (data?.PotentialCreate?._id) {
            await _updateTradeIn({
               variables: {
                  id: tradeInId,
                  input: {},
                  items: [data.PotentialCreate._id]
               },
               refetchQueries: refetchAll
            })
         }
      }, 'Category added', 'Category added')
   }

   const onViewStock = (item: TradeInItem) => () => {
      if (item.brandItem) {
         navigate(BrandItemPagePath(brandHouseId, item.brandItem!._id, false))
      } else if (item.stockItem) {
         navigate(StockItemEditPagePath(brandHouseId, item.stockItem!.shopId, item.stockItem!._id))
      }
   }

   const onChangeSize = async (sizeFragment: BrandTradeInSizeFragment) => {
      Dialogs.confirm({
         title: 'Confirm change',
         subtitle: `Please confirm that you wish to change the tradein item to ${sizeFragment.sku}`
      }, async () => {
         const language = consumer?.language;
         const name = getValue(sizeFragment?.brandVariant?.brandProduct?.nameLocalized, language)
         const size = getValue(sizeFragment?.sizeLocalized, language)
         const color = getValue(sizeFragment?.brandVariant?.colorLocalized, language)
         await updateItem(tradeInItem!._id, {
            name: name || undefined,
            sku: sizeFragment.sku || undefined,
            brandProductVariantId: sizeFragment.brandVariant?._id,
            brandProductSizeId: sizeFragment._id,
            size: size || undefined,
            color: color || undefined
         });
         setTradeInItem(undefined);
      })
   }
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const rejectionReasons: Record<EnumTradeInItemRejected, string> = {
      [EnumTradeInItemRejected.ITEMBADCONDITION]: "Bad Condition",
      [EnumTradeInItemRejected.ITEMMISSINGCARELABEL]: "Missing Care Label",
      [EnumTradeInItemRejected.ITEMNOTINPACKAGE]: "Not in Package",
      [EnumTradeInItemRejected.ITEMPARTOFSET]: "Part of a Set",
      [EnumTradeInItemRejected.ITEMWRONGBRAND]: "Wrong Brand",
      [EnumTradeInItemRejected.NOSIZEONITEM]: "No Size on Item",
      [EnumTradeInItemRejected.OTHER]: "Other"
   };

   return <Page>
      <PageHeader title={"TradeIn " + tradeIn?.tradeInNr} onBack={true}>
         <Button disabled>
            <BodyText type={"subtitle1"}>Created at {DateFormat.toReadable(tradeIn?.dateTime)}</BodyText>
         </Button>
         <Button onClick={onDelete} startIcon={<DeleteForeverIcon/>}>Delete</Button>
      </PageHeader>

      <Grid container spacing={2}>
         <Grid item xs={8}>
            <PageContent>
               <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box><TitleText type={"h2"}>Items {items?.length}</TitleText></Box>
                  <Box sx={{display: 'flex', columnGap: '4px'}}>
                     <Button variant={"outlined"}
                             onClick={handleClick}
                             aria-controls={open ? 'extra-menu' : undefined}
                             aria-haspopup="true"
                             aria-expanded={open ? 'true' : undefined}
                     >Add</Button>
                     <Menu
                        anchorEl={anchorEl}
                        id="extra-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                           style: {
                              maxHeight: '400px'
                           }
                        }}
                     >
                        <MenuItem onClick={() => setAdding(true)}>
                           <ListItemIcon>
                              <LanguageIcon fontSize="small"/>
                           </ListItemIcon>
                           Potentials
                        </MenuItem>
                        <Divider/>
                        {data?.TradeInById?.shop?.categories?.map((category, i) => {
                           return <MenuItem key={'categoryx_' + category?._id}
                                            onClick={createCategory(shop._id, category!._id)}>
                              <ListItemIcon>
                                 <LanguageIcon fontSize="small"/>
                              </ListItemIcon>
                              {category?.name}
                           </MenuItem>

                        })}
                     </Menu>
                     <Button variant={"outlined"} disabled={selected.length === 0} onClick={onRemoveItem}
                             size={"small"}>Reject
                        ({selected.length})
                     </Button>
                  </Box>
               </Box>
               <Divider/>
               {items?.map((titem, idx) => {
                  const item = titem as TradeInItem;

                  let color = item.color;
                  let size = item.size;
                  let sku = item.sku;

                  size = (size === 'N/A' ? undefined : size)
                  color = (color === 'N/A' ? undefined : color)

                  return <Box sx={{
                     display: 'flex', gap: '10px', marginTop: '20px', justifyContent: 'flex-start',
                     opacity: item.rejected ? 0.3 : 1
                  }}>
                     <Box sx={{flex: '0 1 0'}}>
                        #{idx + 1}
                     </Box>
                     <Box sx={{display: 'flex', gap: '10px', flexWrap: 'wrap', flex: '1 1 auto'}}>
                        <Thumbnails imageUrls={item.imageUrls || []} size={150} maxImages={1}/>
                        <Box sx={{display: 'flex', flexDirection: 'column', flex: '3 1 0'}}>
                           <BodyText type={"subtitle1"}>
                              {item.name || item.productVariant?.product?.name}
                           </BodyText>
                           {(!isBrandLogin && !item.rejected && !item.brandItem) &&
                              <Box>
                                 <Button
                                    variant={"outlined"}
                                    title={"Correct the received product"} className={classes.editItem}
                                    color={"secondary"} size={"small"}
                                    onClick={() => setTradeInItem(item as TradeInItem)}>
                                    <CreateIcon fontSize={"small"} sx={{marginRight: '10px'}}/> Identify item
                                 </Button>
                              </Box>
                           }
                           {item.rejected && <Box>
                              <Chip color="primary"
                                    label={`Rejected ${rejectionReasons[item.rejected] || "Unknown Reason"}`}/>
                           </Box>}
                           <Box sx={{marginTop: '20px'}}>
                              {color && <BodyText type={"body2"} style={{whiteSpace: 'nowrap'}}>
                                 Color: {color}
                              </BodyText>}
                              {size && <BodyText type={"body2"} style={{whiteSpace: 'nowrap'}}>
                                 Size: {size}
                              </BodyText>}
                              {item.wholeSale && <Box sx={{whiteSpace: 'nowrap'}}>
                                 <BodyText type={"body2"}>
                                    Category: {item.wholeSale.name}
                                 </BodyText>
                              </Box>}
                              {item.value && <Box sx={{whiteSpace: 'nowrap'}}>
                                 <BodyText type={"body2"}>
                                    TradeIn Value: {Math.round(item.value || 0) + ' ' + item.currency}
                                 </BodyText>
                              </Box>}
                              {!item.wholeSale && <Box sx={{whiteSpace: 'nowrap'}}>
                                 {sku && <BodyText type={"body2"}>
                                    SKU: {sku}
                                 </BodyText>}
                                 {item.potential && <>
                                    <BodyText type={"body2"}>
                                       RRP: {item.potential?.rrp + ' ' + shop.currency}
                                    </BodyText>
                                    {item.potential?.sp &&
                                       <BodyText type={"body2"}>
                                          Price: {item.potential?.sp + ' ' + shop.currency}
                                       </BodyText>
                                    }
                                 </>}
                              </Box>}
                              {item.potential?.externUrl &&
                                 <a style={{marginTop: '10px'}} href={item.potential.externUrl} target={"_blank"}
                                    rel={"noreferrer"}>Original order</a>
                              }
                           </Box>
                        </Box>
                        <Box sx={{flex: '3 1 0', display: 'flex', justifyContent: 'flex-start'}}>
                           <Box>
                              <TitleText type={"subtitle1"}>Rate item condition</TitleText>
                              <Condition
                                 readOnly={isBrandLogin || (item.brandItem?.condition !== undefined)}
                                 conditions={(shop.conditions || []).map(cond => {
                                    return {
                                       condition: cond!.condition || 0,
                                       name: cond!.name || 'Condition',
                                       description: cond!.description || ''
                                    }
                                 })}
                                 current={item.brandItem?.condition || item.condition || null}
                                 onConditionChange={(condition) => onConditionChange(item as TradeInItem, condition)}
                              />
                           </Box>
                           <Box sx={{display: 'flex', flexDirection: 'column'}}>
                              {!isBrandLogin && <>
                                 {item.rejected ?
                                    <Button onClick={() => onRejectItem(item as TradeInItem, false)}
                                            disabled={!item.condition}
                                            variant={"outlined"}>Undo rejection</Button>
                                    : <>
                                       {!(item.brandItem || item.stockItem) &&
                                          <Button onClick={onAccept(item as TradeInItem)}
                                                  disabled={!item.condition || !tradeIn?.registered}
                                                  sx={{whiteSpace: "nowrap"}}
                                                  variant={"outlined"}>{tradeIn?.registered ? "Accept" : "Not Ready"}</Button>
                                       }
                                    </>
                                 }
                              </>}
                              {(item.brandItem || item.stockItem) &&
                                 <Button onClick={onViewStock(item as TradeInItem)}
                                         variant={"outlined"}>View in stock</Button>
                              }
                              {!(item.stockItem || item.brandItem) &&
                                 <Checkbox checked={selected.includes(String(item._id))} onChange={() => {
                                    if (selected.includes(String(item._id))) {
                                       setSelected(selected.filter(sel => sel !== String(item._id)))
                                    } else {
                                       setSelected([...selected, String(item._id)])
                                    }
                                 }}/>
                              }
                           </Box>
                        </Box>
                     </Box>
                  </Box>
               })}
            </PageContent>
         </Grid>
         <Grid item xs={4}>
            <PageContent>
               <ConsumerPanel consumerId={tradeIn!.consumerId}/>

               <div style={{height: '30px'}}/>

               {(tradeIn?.vouchers && tradeIn?.vouchers.length > 0) && <>
                  <TitleText type={"h1"}>Voucher</TitleText>
                  {tradeIn?.vouchers?.map(voucher => {
                     return <div key={'voucherkey_' + voucher?.code}>
                        <div><Link
                           onClick={() => navigate(VoucherPagePath(brandHouseId, shop._id, voucher?._id))}>{voucher?.code}</Link>
                        </div>
                        <div>{voucher?.value + " " + voucher?.currency}
                           <Box sx={{display: 'inline-block', marginLeft: '10px'}}>
                              ({voucher?.used && 'Used ' + DateFormat.toDate(voucher!.used)}
                              {!voucher?.used && "Active: " + (voucher?.activated ? 'Yes' : 'No')}
                              )
                           </Box>
                        </div>
                     </div>
                  })}
                  <div style={{height: '30px'}}/>
               </>}

               {tradeIn?.payoutType === EnumTradeInPayoutType.BANK && <Box>
                  <TitleText type={"h1"}>Payout</TitleText>
                  <BodyText type={"body1"}>Bank
                     Account: {tradeIn?.payoutBank?.regNr} - {tradeIn?.payoutBank?.accountNr}</BodyText>
                  <BodyText
                     type={"body1"}>Amount: {(tradeIn?.payoutBank?.amount || 0).toFixed(2)} {tradeIn?.payoutBank?.amountCurrency}</BodyText>
                  <div style={{height: '30px'}}/>
               </Box>}

               {(tradeIn?.circularPoints && tradeIn?.circularPoints.length > 0) && <>
                  <TitleText type={"h1"}>Circular points</TitleText>
                  {tradeIn?.circularPoints?.map(point => {
                     return <div key={'voucherkey_' + point?._id}>
                        <div>{point?.value + " (Active: " + point?.activated + ")"}</div>
                     </div>
                  })}
                  <div style={{height: '30px'}}/>
               </>}

               <TitleText type={"h1"}>Label</TitleText>
               <BodyText type={"body1"}>
                  Status: {getTradeInStatus(tradeIn?.registered, tradeIn?.shippingCarrierId, tradeIn?.label?.deliveryDate, tradeIn?.label?.deliveryStatus)}
               </BodyText>
               <a rel={"noreferrer"} target={"_blank"} href={tradeIn?.label?.url || '#'}>Download shipping label</a>
               {tradeIn?.label?.qrUrl &&
                  <a rel={"noreferrer"} target={"_blank"} href={tradeIn?.label?.qrUrl || '#'}>Download QR</a>}
               <a rel={"noreferrer"} target={"_blank"} href={tradeIn?.label?.trackingUrl || '#'}>Tracking</a>

               <div style={{height: '30px'}}/>

               <TitleText type={"h1"}>TradeIn Ready</TitleText>
               <FormControlLabel
                  control={<Switch size="small" color={"primary"} checked={!!tradeIn?.registered}
                                   onChange={onToggleReceived}/>}
                  label={tradeIn?.registered ? DateFormat.toPresent(tradeIn?.registeredTs) : "Not yet"}
               />
               {tradeIn?.registered && !tradeIn?.notified && <div>Mail will be sent soon</div>}
               {tradeIn?.registered && tradeIn?.notified && <div>Mail was sent</div>}

            </PageContent>
         </Grid>
      </Grid>
      <ObjectHistory refId={tradeInId} secondary={(items || []).map(item => ({
         refId: item!._id,
         title: item!.name!
      }))}/>
      <Dialog open={adding} fullWidth={true} maxWidth={"md"} onClose={() => setAdding(false)}>
         <DialogTitle>
            Add to tradein?
         </DialogTitle>
         <DialogContent>
            <PotentialsList shopId={shop._id} onPotentialSelect={async (row) => {
               if (row._id) {
                  await SavingUI.process(async () => {
                     await updateTradeIn(tradeInId, {}, [row._id]);
                  }, 'Tradein modified', 'Please wait')
               } else {
                  alert('No id!')
               }
            }} consumerId={consumer?._id} hideTradeIns={true}/>
         </DialogContent>
      </Dialog>
      {!!tradeInItem && <BrandTradeInDialog
         brandHouseId={brandHouseId}
         onCancel={() => setTradeInItem(undefined)}
         onUpdate={onChangeSize}
         description={[{
            field: 'Name',
            value: tradeInItem?.name || 'Unknown'
         }, {
            field: 'Color',
            value: tradeInItem?.color || 'Unknown'
         }, {
            field: 'Size',
            value: tradeInItem?.size || 'Unknown'
         }]}
      />}
   </Page>
}

const useStyles = makeStyles({
   editItem: {}
})

const getValue = (localized?: (LocalizationEntry | null)[] | null, defaultLanguage?: string | null) => {
   const entry = localized?.find(loc => loc?.locale === defaultLanguage);
   if (entry) {
      return entry.value;
   } else {
      return localized?.[0]?.value || 'Unknown'
   }
}
