import {useFieldArray, useFormContext} from "react-hook-form";
import {FormInputHtml} from "./FormInputHtml";
import {FormInputText} from "./FormInputText";
import * as React from "react";
import {StandardTextFieldProps} from "@mui/material/TextField/TextField";
import {useEditorState} from "react-simple-wysiwyg";
import GetAppIcon from "@mui/icons-material/GetApp";

export interface LocalizedField {
   locale: string
   value: string
}

type FormInputLocalizedProps = StandardTextFieldProps & {
   field: any
   locale: string
   label: string
   html?: boolean
   placeholders?: LocalizedField[]
}

export const FormInputLocalized = ({
                               field, locale, label, html, placeholders,
                               ...rest
                            }: FormInputLocalizedProps) => {
   const form = useFormContext<any>();
   const {control} = form;

   const {fields} = useFieldArray<any>({
      control,
      name: field!
   });

   return <>{fields.map((f: any, i) => {
      const place = placeholders?.find(res => res?.locale === locale)
      if (f.locale === locale) {
         if (html) {
            return <FormInputHtml key={'localized_' + field + '_' + i} placeholder={place?.value}
                                  name={`${field}[${i}].value`}
                                  label={label} height={"150px"}
                                  toolbarButton={place?.value ? <CopyText text={place?.value}/> : <></>}
            />
         } else {
            return <FormInputText key={'localized_' + field + '_' + i} placeholder={place?.value}
                                  name={`${field}[${i}].value`} label={label} {...rest}/>
         }
      } else {
         return null;
      }
   })}</>
}

type CopyTextProps = {
   text: string
}

const CopyText = ({
                     text
                  }: CopyTextProps) => {
   const editorState = useEditorState();

   const onCopyText = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (!editorState.$selection) {
         editorState.$el?.focus()
      }
      document.execCommand('insertHTML', false, text);
   }

   if (editorState.htmlMode) {
      return null;
   }

   return <button type={"button"} className={"rsw-btn"} onClick={onCopyText}><GetAppIcon sx={{fontSize: '14px'}}/>
   </button>
}
