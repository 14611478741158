import {FormControlProps} from "@mui/material/FormControl/FormControl";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FormInputFree} from "./FormInputFree";
import * as React from "react";
import {LocalizedField} from "./FormInputLocalized";

type FormSelectLocalizedProps = FormControlProps & {
   field: any
   locale: string
   label: string
   options: string[]
   placeholders?: LocalizedField[]
}

export const FormSelectLocalized = ({
                                field, locale, label, placeholders, options, ...rest
                             }: FormSelectLocalizedProps) => {
   const form = useFormContext<any>();
   const {control} = form;

   const {fields} = useFieldArray<any>({
      control,
      name: field!
   });

   return <>{fields.map((f: any, i) => {
      const place = placeholders?.find(res => res?.locale === locale)
      if (f.locale === locale) {
         return <FormInputFree
            key={'selectlocalized_' + locale + '_' + i}
            name={`${field}[${i}].value`} control={control}
            label={label}
            placeholder={place?.value}
            options={options}/>
      } else {
         return null;
      }
   })}</>
}
