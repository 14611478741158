import {LocalizationEntryInput} from "../../Queries"
import {LocalizedField} from "../shop/wholesale/form/FormInputLocalized";

type ToLocalizedProps = {
   locales: string[]
   data?: (LocalizationEntryInput | null)[] | null
}

// convert LocalizationEntryInput[] to LocalizedField
export const toLocalizedField = ({locales, data}: ToLocalizedProps) => {
   const result: LocalizedField[] = []
   for (const locale of locales) {
      const value = data?.find(entry => entry?.locale === locale)
      result.push({
         locale: locale,
         value: value?.value || ''
      })
   }
   return result
}

export const fromLocalizedField = (input?: LocalizedField[]): LocalizationEntryInput[] | undefined => {
   return input?.filter(inp => !!inp.value);
}
