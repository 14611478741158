import {FlexBrandItemFragment} from "../../Queries";
import {Box} from "@mui/material";
import * as React from "react";
import {format} from 'date-fns';
import {BodyText} from "../../layout/Typography";

type TakeActionProps = {
   item?: FlexBrandItemFragment
}

export const BrandItemTakeActionColumn = ({item}: TakeActionProps) => {
   const unsolvedProblems = item?.problems?.filter(problem =>
      problem && !problem.solved && problem.description
   ) || [];

   if (unsolvedProblems.length === 0) {
      return null;
   }

   return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
         {unsolvedProblems.map((problem, index) => (
            <Box key={index} sx={{display: 'flex', gap: 1}}>
               <BodyText type="body2" sx={{color: '#a0a0a0', whiteSpace: 'nowrap'}}>
                  {problem?.createdAt ? format(new Date(problem.createdAt), 'yyyy-MM-dd') : ''}
               </BodyText>
               <BodyText type="body2">
                  {problem?.description}
               </BodyText>
            </Box>
         ))}
      </Box>
   );
};