import * as React from "react";
import {Dialog} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import {CloseIcon} from "./Icons";
import {CSSProperties} from "react";

type ThumbnailProps = {
   image?: string
   size: number
   hideBorder?: boolean
   onClick?: (e?:any) => void
   style?: CSSProperties | undefined;
}

export const imageUnknownUrl = "https://storageaccountourimbea2.blob.core.windows.net/web/unknown.png"

export const Thumbnail = (props: ThumbnailProps) => {
   const [preview, setPreview] = React.useState<boolean>(false);
   const [displayed, setDisplayed] = React.useState<boolean>(false);
   const classes = useStyles();
   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

   const onShow = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setPreview(true);
   }

   const onPreviewClose = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setPreview(false);
   }

   const onLoadImage = () => {
      setDisplayed(true);
   }

   const loadingPlaceholder = displayed ? {} : {
      backgroundSize: '100px, 100px, contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: 'url("https://storageaccountourimbea2.blob.core.windows.net/web/imgloading.gif")',
      height: props.size,
   }

   const hideBorder: any = {};
   if (props.hideBorder) {
      hideBorder.border = '1px solid transparent'
   }

   return (
      <div className={classes.container} style={{
         overflow: "hidden",
         width: props.size,
         ...loadingPlaceholder,
         ...hideBorder,
         ...(props.style||{})
      }}>
         <img
            onError={(e: any) => {
               console.log(e);
               e.target.onerror = null;
               e.target.src = imageUnknownUrl
            }}
            onLoad={onLoadImage}
            style={{
               display: displayed ? 'block' : 'none',
               height: props.size
            }}
            src={props.image || imageUnknownUrl} onClick={props.onClick || onShow} title={"Click to view image"}
            alt={"Thumbnail"}/>
         <Dialog
            onClick={onPreviewClose}
            onClose={onPreviewClose}
            open={preview}
            fullScreen={fullScreen}
            maxWidth={"md"}
         >
            <div onClick={onPreviewClose} style={{
               backgroundColor: 'rgba(255,255,255,0.5)',
               borderRadius: '5px',
               padding: '5px 2px 0px 2px',
               cursor: 'pointer',
               position: 'absolute',
               right: '5px',
               top: '5px'
            }}>
               <CloseIcon iconcolor={'#FF5376'}/>
            </div>

            <img src={props.image || imageUnknownUrl} alt="current-img" style={{width: "100%"}}/>
         </Dialog>
      </div>
   );
}


const useStyles = makeStyles(theme => ({
   container: {
      display: 'flex',
      justifyContent: "center",
      border: '1px solid #e0e0e0',
      margin: '5px',
      alignItems: "baseline",
      cursor: 'pointer'
   }
}));

