import writeXlsxFile from 'write-excel-file'
import {BrandItem, LocalizationEntry, useExportBrandItemsQuery} from "../../Queries";
import {Box, LinearProgress} from "@mui/material";
import {useEffect} from "react";

export const ExportToExcel = async (language: string, currency: string, data: BrandItem[]) => {
   // @ts-ignore
   const schema: any = [{
      column: "ID",
      type: Number,
      value: (object: BrandItem) => object.itemNr
   }, {
      column: "CREATED",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: BrandItem) => new Date(object.createdAt!)
   }, {
      column: "SOURCE",
      type: String,
      value: (object: BrandItem) => object.tradeInItem ? 'TRADEIN' : 'MANUAL'
   }, {
      column: "ITEMSKU",
      type: String,
      value: (object: BrandItem) => object.itemSku
   }, {
      column: "CONDITION",
      type: Number,
      value: (object: BrandItem) => object.condition
   }, {
      column: "STATE",
      type: String,
      value: (object: BrandItem) => object.itemState
   }, {
      column: "NOTE",
      type: String,
      value: (object: BrandItem) => object.note
   }, {
      column: "PROBLEMS",
      type: String,
      value: (object: BrandItem) => (object.hasProblems || object.hasErrors) ? "YES" : "NO"
   }, {
      column: "MISSING",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: BrandItem) => object.missing ? new Date(object.missing) : undefined
   }, {
      column: "LOCATION",
      type: String,
      value: (object: BrandItem) => object.stockLocation
   }, {
      column: "IMAGE",
      type: String,
      value: (object: BrandItem) => object.imageUrls?.[0]
   }, {
      column: "PRODUCT",
      type: String,
      value: (object: BrandItem) => lookup(object.nameLocalized, language, 'en')
   }, {
      column: "RRP",
      type: Number,
      value: (object: BrandItem) => object.beforePrices?.find(rrp => compare(rrp?.currency, currency))?.value
   }, {
      column: "EAN",
      type: String,
      value: (object: BrandItem) => object.itemEan
   }, {
      column: "COLOR",
      type: String,
      value: (object: BrandItem) => lookup(object.colorLocalized, language, 'en')
   }, {
      column: "SIZE",
      type: String,
      value: (object: BrandItem) => lookup(object.sizeLocalized, language, 'en')
   }, {
      column: "ORGEAN",
      type: String,
      value: (object: BrandItem) => object.originalEan
   }, {
      column: "ORGSKU",
      type: String,
      value: (object: BrandItem) => object.originalSku
   }, {
      column: "SEASON",
      type: String,
      value: (object: BrandItem) => object.seasons?.map(season => season?.name).join(", ")
   }, {
      column: "VENDOR",
      type: String,
      value: (object: BrandItem) => object.vendor
   }, {
      column: "PRODUCTTYPE",
      type: String,
      value: (object: BrandItem) => lookup(object.typeLocalized, language, 'en')
   }, {
      column: "TAGS",
      type: String,
      value: (object: BrandItem) => object.tags?.join(", ")
   }, {
      column: "SOLD",
      type: String,
      value: (object: BrandItem) => object.isSold ? "YES" : "NO"
   }, {
      column: "SELLINGPRICE",
      type: Number,
      value: (object: BrandItem) => object.orderline?.salesPricePreTax
   }, {
      column: "TRADEINVALUE",
      type: Number,
      value: (object: BrandItem) => object.tradeInItem?.value
   }];

   await writeXlsxFile(data, {
      schema,
      fileName: 'items-' + Date.now() + '.xlsx'
   })
}


const lookup = (localizations?: (LocalizationEntry | null)[] | null, language?: string, defaultLanguage?: string): string | undefined => {
   const internalFind = (lang?: string) => {
      return localizations?.find(loc => compare(loc?.locale, lang))
   }
   const matchingLocalization = internalFind(language);
   if (!matchingLocalization) {
      if (defaultLanguage) {
         return internalFind(defaultLanguage)?.value;
      } else {
         return localizations?.[0]?.value
      }
   }
   return matchingLocalization.value
}

const compare = (s1?: string, s2?: string) => {
   if (s1 && s2) {
      return s1.localeCompare(s2, 'en', {sensitivity: 'base'}) === 0
   } else {
      return false;
   }
}

type BrandItemExportProps = {
   language: string
   currency: string
   ids: string[]
}

export const BrandItemExport = ({language, currency, ids}: BrandItemExportProps) => {
   const {data} = useExportBrandItemsQuery({
      variables: {
         brandItemIds: ids,
         currency: currency
      }
   });

   useEffect(() => {
      if (data?.BrandItems) {
         (async function () {
            const items = ((data.BrandItems || []) as BrandItem[]).map(item => item.resolved) as BrandItem[]
            await ExportToExcel(language, currency, items);
         })();
      }
   }, [currency, data, ids, language])

   if (!data) {
      return <LinearProgress/>
   }

   return <Box>Data exported</Box>
}