import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import PageContent from "../../../../layout/PageContent";
import Button from "@mui/material/Button";
import {Dialogs} from "../../../../DialogProvider";
import {Box, Card, Chip, IconButton, LinearProgress} from "@mui/material";
import {DTCPotentialsList} from "./views/DTCPotentialsList";
import {DTCEditPath} from "../edit/DTCEditPage";

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopIcon from '@mui/icons-material/Stop';
import useBrandRuleDelete from "./hooks/useBrandRuleDelete";
import {DTCPagePath} from "../main/DTCPage";
import {
   BrandRule,
   BrandRuleStatisticsDocument,
   DtcPotentialsListPaginationDocument, DtcShopFragment,
   useBrandRuleActivationMutation,
   useBrandRuleCreateMutation,
   useBrandRuleStatisticsQuery,
   useDtcBrandRuleQuery,
   useDtcBrandRulesQuery,
   useDtcRebuildStatisticsMutation,
   useDtcSendMailsMutation,
   useDtcViewBrandByIdQuery
} from "../../../../Queries";
import PageContentTabs from "../../../../layout/PageContentTabs";
import AddIcon from "@mui/icons-material/Add";
import {Email} from "@mui/icons-material";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../../../atoms/CurrentUser";
import {StatCard} from "./views/StatCard";
import {AgeView} from "./AgeView";
import {PeriodView} from "./PeriodView";
import {MinimumPriceView} from "./MinimumPriceView";
import {ExpressionView} from "./ExpressionView";
import RefreshIcon from "@mui/icons-material/Refresh";

export const DTCViewPath = (brandHouseId: string, shopId: string, brandRuleId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/dtc/" + brandRuleId;
}

export const DTCViewPage = () => {
   const {
      brandHouseId,
      shopId,
      brandRuleId
   } = useParams<{ brandHouseId: string, shopId: string, brandRuleId: string }>();
   const [activeBrandRuleId, setActiveBrandRuleId] = useState<string>(brandRuleId);
   const navigate = useNavigate();
   const {data: brandRules} = useDtcBrandRulesQuery({
      variables: {
         shopId: shopId
      },
      fetchPolicy: "no-cache"
   })
   const [brandRuleCreate] = useBrandRuleCreateMutation()
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   useEffect(() => {
      if (activeBrandRuleId !== brandRuleId) {
         navigate(DTCViewPath(brandHouseId, shopId, activeBrandRuleId), {replace: true});
      }
   }, [activeBrandRuleId, brandHouseId, brandRuleId, navigate, shopId])

   if (!brandRules) {
      return <LinearProgress/>
   }

   const onBrandRuleCreate = async () => {
      const {data} = await brandRuleCreate({
         variables: {
            shopId: shopId
         },
         refetchQueries: ['BrandRules']
      });
      if (data?.BrandRuleCreate?._id) {
         navigate(DTCEditPath(brandHouseId, shopId, data?.BrandRuleCreate?._id))
      } else {
         Dialogs.info({
            title: 'Unable to create brandrule',
            subtitle: 'Unexpected error occured'
         })
      }
   }


   const rules = brandRules.BrandRules || []
   return <>
      <PageContentTabs tab={brandRuleId} tabs={rules.map(rule => ({
         id: rule?._id,
         label: "Rule #" + rule?.priority
      }))} onClick={(tab) => {
         setActiveBrandRuleId(tab);
      }} commands={
         isAdministrator && <Button variant={"outlined"} startIcon={<AddIcon/>} onClick={onBrandRuleCreate}>Rule</Button>
      }
      />

      {activeBrandRuleId === brandRuleId &&
         <DTCViewInnerPage brandHouseId={brandHouseId} shopId={shopId} brandRuleId={activeBrandRuleId}/>
      }
   </>
};


type DTCViewProps = {
   brandHouseId: string
   shopId: string
   brandRuleId: string
}

const DTCViewInnerPage = ({brandHouseId, shopId, brandRuleId}: DTCViewProps) => {
   const {data: dataBrand} = useDtcViewBrandByIdQuery({variables: {shopId}});
   const {data: dataRule} = useDtcBrandRuleQuery({
      variables: {
         ruleId: brandRuleId
      }
   });
   const [brandRuleActivation] = useBrandRuleActivationMutation()
   const [distributeMails] = useDtcSendMailsMutation()
   const brandRuleDelete = useBrandRuleDelete();
   const navigate = useNavigate();
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   const onDelete = async () => {
      Dialogs.confirm({
         title: 'Confirm deletion of brandrule?',
         subtitle: 'This action cannot be undone'
      }, async () => {
         await brandRuleDelete(shopId, brandRuleId);
         navigate(DTCPagePath(brandHouseId, shopId));
      })
   }

   const onSend = async () => {
      Dialogs.confirm({
         title: 'Confirm sending mails for this shop?',
         subtitle: 'This action cannot be undone'
      }, async () => {
         await distributeMails({
            variables: {
               shopId
            }
         })
      })
   }

   const onActivateToggle = (active: boolean) => async () => {
      Dialogs.confirm({
         title: "Are you sure you wish to " + (active ? "activate" : "de-activate") + "  this rule?"
      }, async () => {
         await brandRuleActivation({
            variables: {
               shopId, ruleId: brandRuleId, active
            },
            refetchQueries: [DtcPotentialsListPaginationDocument, BrandRuleStatisticsDocument]
         });
      })
   }

   if (!dataBrand?.ShopById || !dataRule?.BrandRule) {
      return <LinearProgress/>
   }
   const shop = dataBrand.ShopById
   const brandRule = dataRule.BrandRule

   return (
      <PageContent>
         <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
            <Box sx={{
               display: 'flex',
               justifyContent: 'space-between',
               marginBottom: '10px'
            }}>
               <Box sx={{display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap', alignItems: 'center'}}>
                  <Chip label={<ExpressionView rule={brandRule as BrandRule}/>}/>
                  <Chip label={<PeriodView from={brandRule?.periodFrom} to={brandRule?.periodTo}/>}/>
                  <Chip label={<AgeView maximumAge={brandRule?.maximumAge} minimumAge={brandRule?.minimumAge}/>}/>
                  <Chip label={<MinimumPriceView currency={shop?.currency} price={brandRule.minimumPrice}/>}/>
               </Box>
               <Box>
                  {isAdministrator && <Button
                     startIcon={<Email/>}
                     onClick={onSend}
                     color={"secondary"}
                     variant={"text"}>Send mails</Button>}
                  {brandRule && !brandRule.active ?
                     <Button
                        startIcon={<PlayCircleFilledWhiteIcon/>}
                        onClick={onActivateToggle(true)}
                        color={"secondary"}
                        variant={"text"}>Activate</Button>
                     :
                     <Button
                        startIcon={<StopIcon/>}
                        onClick={onActivateToggle(false)}
                        color={"secondary"}
                        variant={"text"}>Deactivate</Button>
                  }
                  <Button
                     startIcon={<EditIcon/>}
                     onClick={() => navigate(DTCEditPath(brandHouseId, shopId, brandRuleId))}
                     color={"secondary"}
                     variant={"text"}>Edit</Button>
                  <Button
                     startIcon={<DeleteForeverIcon/>}
                     onClick={onDelete}
                     color={"secondary"}
                     variant={"text"}>Delete</Button>
               </Box>
            </Box>
            <Box sx={{display: 'flex', flexGrow: 1}}>
               <DTCStatistics brandRuleId={brandRuleId} currency={shop?.currency || '???'} shop={shop}/>
            </Box>
         </Box>
         <DTCPotentialsList brandRuleId={brandRuleId} shop={shop} activated={brandRule?.active || false}/>
      </PageContent>
   );
}


type DTCStatisticsProps = {
   brandRuleId: string
   currency: string
   shop: DtcShopFragment
}

const DTCStatistics = ({
   shop,
                          brandRuleId,
                          currency
                       }: DTCStatisticsProps) => {
   const {data} = useBrandRuleStatisticsQuery({
      variables: {
         brandRuleId
      },
      fetchPolicy: "no-cache",
      pollInterval: 4000
   });
   const [rebuild] = useDtcRebuildStatisticsMutation()

   const onRebuild = async () => {
      if (data?.BrandRule?.statistics) {
         await rebuild({
            variables: {
               shopId: data?.BrandRule?.shopId
            }
         })
      } else {
         alert('Too soon')
      }
   }

   const analytics = data?.BrandRule?.statistics
   const polling = !data?.BrandRule?.statistics

   return <Box sx={{position: 'relative', width: '100%', marginBottom: '10px'}}>
      <Card sx={{display: 'flex', flexDirection: 'row', flexGrow: 1, backgroundColor: '#f4f4f4'}}>
         <StatCard title={"Consumers with offers"} polling={polling}
                   value={(analytics?.consumers || 0) + ' (' + (analytics?.items || 0) + ' items)'}/>
         <StatCard title={shop?.settings?.klaviyoConfig?.enabled ? "Exporting" : "Sending soon"} polling={polling}
                   value={(analytics?.sendingNow || 0) + (shop?.settings?.klaviyoConfig?.enabled ? ' offers' : ' mails')}/>
         <StatCard title={"Max voucher cost"} polling={polling}
                   value={analytics?.voucherSum + ' ' + currency}/>
      </Card>
      <Box sx={{position: 'absolute', top: 0, right: 0}}>
         <IconButton disabled={!analytics} onClick={onRebuild}><RefreshIcon/></IconButton>
      </Box>
   </Box>
}

