import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IDViewText} from "../../../components/MongoId";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {Alert, CircularProgress, Dialog, DialogContent, Grid, IconButton, LinearProgress} from "@mui/material";
import PageGroup from "../../../layout/PageGroup";
import {BodyText, TitleText} from "../../../layout/Typography";
import makeStyles from '@mui/styles/makeStyles';
import {Thumbnails} from "../../../components/Thumbnails";
import Button from "@mui/material/Button";
import {
   Order2Item,
   OrderPageOrderDocument,
   OrderPageOrderLineFragment,
   useOrderExportToWareHouseMutation,
   useOrderPageBrandQuery,
   useOrderPageOrderQuery,
   useOrderPageSynchronzieOrderMutation,
   useOrderPageUpdateBrandItemMutation,
   useOrderStatusFromWarehouseMutation,
   useOrderSynchronizeMutation,
   usePrintDeliveryNoteMutation
} from "../../../Queries";
import {Dialogs} from "../../../DialogProvider";
import {CheckBoxOutlineBlank, CheckBoxOutlined, EditLocationAlt, RequestPage, Upgrade} from "@mui/icons-material";
import {StockItemEditPagePath} from "../stockitems/edit/StockItemEditPage";
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../../../atoms/CurrentUser";
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs from "dayjs";
import {BrandItemPagePath} from "../../brand/brandItem/BrandItemPage";
import {Address, AddressEditor} from "./AddressEditor";
import {SavingUI} from "../../../SavingProvider";
import {OrderUpdateReason} from "./OrderUpdateReason";
import {usePrompt} from "../../../components/useBlocker";
import {ObjectHistory} from "../../components/ObjectHistory";
import ReturnedItemStatus from "./OrderReturnItemState";

export const OrderPagePath = (brandHouseId: string, shopId: string, orderId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/orders/" + orderId
}

type UpdateType = 'return' | 'cancel';

type LineUpdateType = {
   lineId: string
   updateType: UpdateType
   reason: string
   unavailble: boolean
}

type LineEditType = {
   line: Order2Item | null
   updateType: UpdateType
}

export const OrderPage: FC<any> = () => {
   const [editShipping, setEditShipping] = React.useState<boolean>(false);
   const [line, setLine] = React.useState<LineEditType | null>(null);
   const [updates, setUpdates] = React.useState<LineUpdateType[]>([]);
   const [updatedAt, setUpdatedAt] = React.useState<string | undefined>();
   const {brandHouseId, shopId, orderId} = useParams<{ brandHouseId: string, shopId: string, orderId: string }>();
   const {data, startPolling, stopPolling} = useOrderPageOrderQuery({
      variables: {
         id: orderId
      }
   })

   const {data: brand} = useOrderPageBrandQuery({
      variables: {
         id: shopId
      }
   })
   const classes = useStyles();
   const [synchronize] = useOrderPageSynchronzieOrderMutation()
   const [updateItem] = useOrderPageUpdateBrandItemMutation()
   const [statusFromWarehouse] = useOrderStatusFromWarehouseMutation()
   const [exportToWarehouse] = useOrderExportToWareHouseMutation()
   const [updateOrder] = useOrderSynchronizeMutation()
   const [printOrder] = usePrintDeliveryNoteMutation();

   const navigate = useNavigate();
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);

   useEffect(() => {
      if (updatedAt) {
         const currentUpdatedAt = data?.Order?.updatedAt;
         if (currentUpdatedAt) {
            if (String(currentUpdatedAt) !== updatedAt) {
               stopPolling();
               setUpdatedAt(undefined);
            }
         }
      }
   }, [updatedAt, data, stopPolling])

   usePrompt('Remember to save changes', updates.length > 0)

   if (!data || !data.Order || !brand) {
      return <LinearProgress/>
   }

   const order = data.Order;

   let modified = false;
   let address = order.shippingAddress;
   if (order.shippingAddressInternal?.add1) {
      address = order.shippingAddressInternal
      modified = true;
   }

   if (!address) {
      address = {}
   }

   const onViewInStock = (line: OrderPageOrderLineFragment) => () => {
      if (line.item?.brandItemId) {
         navigate(BrandItemPagePath(brandHouseId, line.item.brandItemId, false))
      } else {
         navigate(StockItemEditPagePath(brandHouseId, shopId, line.item?._id));
      }
   }

   const onReturnSelect = (updateType: 'return' | 'cancel', reason: string, unavailble: boolean) => {
      if (line?.line) {
         if (updates.find(ret => ret.lineId === line.line!.externalLineId)) {
            deSelectLine(line)
         } else {
            setUpdates([...updates, {
               updateType: updateType,
               lineId: line.line.externalLineId,
               reason: reason,
               unavailble: unavailble
            }])
         }
         setLine(null)
      }
   }

   const deSelectLine = (line: LineEditType) => {
      setUpdates(updates.filter(ret => ret.lineId !== line.line?.externalLineId))
   }

   const onPrintNote = async () => {
      await SavingUI.process(async () => {
         await printOrder({
            variables: {
               payload: [orderId, true, "65006857c39a1b0f2acd92ab"]
            }
         })
      }, 'Order printed', 'Printing, please wait');
   }

   const onReturn = async () => {
      if (updates.length === 0) {
         return
      }
      Dialogs.confirm({
         title: 'Confirm changes',
         subtitle: 'Refunds and cancellations of items will be made instantly, and the items will need to be checked into a location before they can be sold again.',
         acceptTitle: 'Confirm changes to order?'
      }, async (accept) => {
         await SavingUI.process(async () => {
            await updateOrder({
               variables: {
                  input: {
                     id: {
                        _id: order._id
                     },
                     externalId: order.externalId,
                     lines: updates.map(ret => {
                        if (ret.updateType === 'return') {
                           return {
                              id: {
                                 externalId: ret.lineId
                              },
                              externalLineId: ret.lineId,
                              returnedReason: ret.reason,
                              returned: true,
                              refund: accept
                           }
                        } else if (ret.updateType === 'cancel') {
                           return {
                              id: {
                                 externalId: ret.lineId
                              },
                              externalLineId: ret.lineId,
                              cancelledReason: ret.reason,
                              cancelled: true,
                           }
                        } else {
                           alert('No support for ' + ret.updateType);
                           throw new Error('No support for ' + ret.updateType)
                        }
                     })
                  }
               }
            })

            /**
             * Update the brand item to be unavailable if the orderline is refunded/cancelled and user selected to make it unavailable
             */
            for (const update of updates) {
               if(update.unavailble) {
                  const orderLine = order.lines?.find(line => line?.externalLineId === update.lineId);
                  const brandItemId = orderLine?.item?.brandItem?._id
                  if(orderLine && brandItemId) {
                     await updateItem({
                        variables: {
                           brandHouseId: brandHouseId,
                           input: {
                              _id: brandItemId,
                              unavailable: orderLine.item?.brandItem?.unavailable || new Date()
                           }
                        },
                        refetchQueries: [{
                           query: OrderPageOrderDocument,
                           variables: {
                              id: orderId
                           }
                        }]
                     })
                  }
               }
            }

            setUpdates([])
         }, 'Order updated', 'Please wait');
      })
   }

   const onSynchronizeOrder = async () => {
      setUpdatedAt(String(order.updatedAt));
      await synchronize({
         variables: {
            shopId: shopId,
            service: 'orders',
            externalId: order.externalId
         }
      });
      startPolling(1000);
   }

   const onStatusFromWarehouse = async () => {
      setUpdatedAt(String(order.updatedAt));
      await statusFromWarehouse({
         variables: {
            orderId: orderId
         }
      });
      startPolling(1000);
   }

   const onUpdateAddress = async (data: Address) => {
      await SavingUI.process(async () => {
         await updateOrder({
            variables: {
               input: {
                  id: {
                     _id: order._id
                  },
                  externalId: order.externalId,
                  shippingAddressInternal: data,
               }
            }
         })
      }, 'Order updated');
      setEditShipping(false);
   }

   const onToggleSilence = async () => {
      await SavingUI.process(async () => {
         await updateOrder({
            variables: {
               input: {
                  id: {
                     _id: order._id
                  },
                  externalId: order.externalId,
                  silenced: !order.silenced
               }
            }
         })
      }, 'Order updated');
   }

   const onExportToWareHouse = async () => {
      await SavingUI.process(async () => {
         await exportToWarehouse({
            variables: {
               orderId: order._id
            }
         })
      }, 'Order updated');
   }

   const makeAvailable = async (orderline: Order2Item) => {
      const brandItemId = orderline.item?.brandItem?._id
      if(brandItemId) {
         Dialogs.confirm({
            title: 'Make item available',
            subtitle: 'This will make the item available for sale again',
         }, async (accept) => {
            await SavingUI.process(async () => {
               await updateItem({
                  variables: {
                     brandHouseId: brandHouseId,
                     input: {
                        _id: brandItemId,
                        unavailable: null
                     }
                  }
               })
            }, 'Order updated', 'Please wait');
         });
      }
   }

   let trackingUrl: string = '#'
   if (order.returnInfo?.carrier) {
      trackingUrl = getTrackingUrl(order.returnInfo?.carrier, order.returnInfo!.number!)
   }

   return <Page>
      <PageHeader
         title={"Order " + (order.orderName || IDViewText(order._id)) + ' ' + dayjs.utc(order.orderDate).format('DD. MMM YYYY')}
         onBack={true}>
         <Button onClick={() => onPrintNote()} disabled={isBrandHouse} variant={"contained"}>
            Print deliverynote</Button>
         <Button onClick={() => onReturn()} disabled={updates.length === 0} variant={"contained"}>
            Save changes</Button>
      </PageHeader>
      <PageGroup>
         <PageContent style={{justifyContent: 'space-between'}}>
            <div>
               {order.lines?.map(orderline => {
                  if (!orderline) {
                     return <></>
                  }
                  const line = orderline;
                  const item = line.item;
                  if (!item) {
                     return <></>
                  }
                  const returned = line.returned;
                  const cancelled = line.cancelled;

                  function isAnyMarked() {
                     return isMarkedReturn() || isMarkedCancelled()
                  }

                  function isMarkedReturn() {
                     return updates.find(ret => ret.lineId === line.externalLineId)?.updateType === 'return';
                  }

                  function isMarkedCancelled() {
                     return updates.find(ret => ret.lineId === line.externalLineId)?.updateType === 'cancel';
                  }

                  return <div className={classes.container} key={'orderitem_' + item._id}>
                     <div className={classes.firstCell}>
                        #{item.itemNr}
                     </div>
                     <div className={classes.imagecell}>
                        <Thumbnails imageUrls={item.imageUrls || []} maxImages={1} size={120}/>
                     </div>
                     <div className={classes.maincell}>
                        <BodyText type={"subtitle1"}>
                           {item.title}
                        </BodyText>
                        {item.color && <BodyText type={"body2"}>
                           Color: {item.color}
                        </BodyText>}
                        {item.size && <BodyText type={"body2"}>
                           Size: {item.size}
                        </BodyText>}
                        {item.itemSku && <BodyText type={"body2"}>
                           SKU: {item.itemSku}
                        </BodyText>}
                        {item.ean && <BodyText type={"body2"}>
                           EAN: {item.ean}
                        </BodyText>}

                        <BodyText type={"subtitle1"}>Original data</BodyText>
                        {item.sku && <BodyText type={"body2"}>
                           Original SKU: {item.sku}
                        </BodyText>}
                        {item.ean && <BodyText type={"body2"}>
                           Original EAN: {item.originalEan}
                        </BodyText>}

                        {order.orderUrl &&
                           <a onClick={(e) => {
                              e.stopPropagation()
                           }} href={order.orderUrl} target={isBrandHouse ? "_top" : "_blank"}
                              rel={"noreferrer"}>Go to order</a>}

                        <BodyText type={"subtitle1"}>Warehouse</BodyText>
                        {item.stockLocation && <BodyText type={"body2"}>
                           Stock Location: {item.stockLocation}
                        </BodyText>}

                        {order.trackingInfo && <div>
                           <BodyText type={"subtitle1"}>Shipping</BodyText>
                           {order.trackingUrl && <a href={order.trackingUrl} target={'_blank'} rel="noreferrer">
                              {order.trackingInfo?.number} ({order.trackingInfo.carrier})
                           </a>}
                           {!order.trackingUrl && <BodyText
                              type={"body2"}>{order.trackingInfo?.number} ({order.trackingInfo.carrier})</BodyText>
                           }
                        </div>
                        }
                     </div>
                     <div>
                        <BodyText type={"subtitle1"}>Prices</BodyText>
                        <BodyText type={"body2"}>
                           Actual price: {line.salesPrice} {line.currency || brand?.ShopById?.currency}<br/>
                           Discounted price: {line.discountedPrice} {line.currency || brand?.ShopById?.currency}
                        </BodyText>
                        <div style={{marginTop: '30px'}}>
                           {!cancelled && !returned && <BodyText type={"subtitle1"}>Change orderline</BodyText>}
                           {returned && <ReturnedItemStatus returned={returned} unavailable={!!item.brandItem?.unavailable} onMakeAvailable={() => makeAvailable(orderline as any)}/>}
                           {!cancelled && !isBrandHouse && !returned && <div>
                              <Button
                                 startIcon={isMarkedReturn() ? <CheckBoxOutlined/> : <CheckBoxOutlineBlank/>}
                                 variant={"text"}
                                 color={isMarkedReturn() ? "primary" : "secondary"}
                                 onClick={() => isAnyMarked() ? deSelectLine({
                                    line: orderline as any,
                                    updateType: 'return'
                                 }) : setLine({
                                    line: orderline as any,
                                    updateType: 'return'
                                 })}>
                                 RETURN
                              </Button>
                           </div>}
                           {cancelled && <Alert severity={"error"} color={"error"}>Cancelled</Alert>}
                           {!returned && !isBrandHouse && !cancelled && <div>
                              <Button
                                 startIcon={isMarkedCancelled() ? <CheckBoxOutlined/> : <CheckBoxOutlineBlank/>}
                                 variant={"text"}
                                 color={isMarkedCancelled() ? "primary" : "secondary"}
                                 onClick={() => isAnyMarked() ? deSelectLine({
                                    line: orderline as any,
                                    updateType: 'cancel'
                                 }) : setLine({
                                    line: orderline as any,
                                    updateType: 'cancel'
                                 })}>
                                 CANCEL
                              </Button>
                           </div>}
                        </div>
                        <div>
                           <Button style={{marginTop: '20px'}} variant={"text"} onClick={onViewInStock(line)}>
                              View stock item
                           </Button>
                        </div>
                     </div>
                  </div>
               })}
            </div>
         </PageContent>
      </PageGroup>
      <PageGroup widths={['50%', '50%']}>
         <PageContent style={{justifyContent: 'space-between'}} commands={
            <>
               <IconButton disabled={updatedAt != null} onClick={onSynchronizeOrder} color={"primary"}>
                  {updatedAt && <><CircularProgress size={24}/></>}
                  {!updatedAt && <RefreshIcon/>}
               </IconButton>
               <IconButton disabled={updatedAt != null} onClick={onStatusFromWarehouse} color={"primary"}>
                  {updatedAt && <><CircularProgress size={24}/></>}
                  {!updatedAt && <RequestPage/>}
               </IconButton>
            </>}>
            <div>
               <TitleText type={"h1"}>Order State</TitleText>
               <BodyText type={"body2"}>
                  <Grid container>
                     <Grid item xs={10}>Fulfillment:</Grid><Grid item xs={2}>{order.fulState}</Grid>
                     <Grid item xs={10}>Payment:</Grid><Grid item xs={2}>{order.payState}</Grid>
                     <Grid item xs={10}>State:</Grid><Grid item xs={2}>{order.orderState}</Grid>
                  </Grid>
               </BodyText>

               <TitleText sx={{marginTop: '15px'}} type={"h1"}>Outbound package</TitleText>
               <Grid container>
                  <Grid item xs={10}>Tracking:</Grid>
                  <Grid item xs={2}>
                     <a href={order.trackingUrl || '#'} target={'_blank'} rel="noreferrer">Link</a>
                  </Grid>
               </Grid>
               <TitleText sx={{marginTop: '15px'}} type={"h1"}>Return package</TitleText>
               <Grid container>
                  <Grid item xs={10}>Label:</Grid>
                  <Grid item xs={2}>
                     <a href={order?.returnLabelUrl || '#'} target={'_blank'} rel="noreferrer">Download label</a>
                  </Grid>
                  <Grid item xs={10}>Tracking:</Grid>
                  <Grid item xs={2}>
                     <a href={trackingUrl} target={'_blank'} rel="noreferrer">Link</a>
                  </Grid>
               </Grid>

               <TitleText sx={{marginTop: '15px'}} type={"h1"}>Events</TitleText>
               <BodyText type={"body2"}>
                  <Grid container>
                     <Grid item xs={10}>Warehouse staff notified</Grid><Grid item xs={2}>{order.notified ?
                     <CheckBoxOutlined fontSize={"small"}/> : <CheckBoxOutlineBlank fontSize={"small"}/>}</Grid>
                     <Grid item xs={10}>Customer notified:</Grid><Grid item xs={2}>{order.notified ?
                     <CheckBoxOutlined fontSize={"small"}/> : <CheckBoxOutlineBlank fontSize={"small"}/>}</Grid>
                     <Grid item xs={10}>Exported to WMS:</Grid><Grid item xs={2}>{order.notified ?
                     <CheckBoxOutlined fontSize={"small"}/> : <CheckBoxOutlineBlank fontSize={"small"}/>}</Grid>
                     <Grid item xs={10}>Silenced:</Grid><Grid item xs={2}>
                     <IconButton sx={{margin: 0, padding: 0}} color={"primary"} onClick={onToggleSilence}>
                        {order.silenced === true ? <CheckBoxOutlined fontSize={"small"}/> :
                           <CheckBoxOutlineBlank fontSize={"small"}/>}
                     </IconButton>
                  </Grid>
                  </Grid>
               </BodyText>
            </div>
         </PageContent>
         <PageContent style={{justifyContent: 'space-between'}} commands={
            <>
               <IconButton onClick={onExportToWareHouse}>
                  <Upgrade/>
               </IconButton>
               <IconButton disabled={updatedAt != null} onClick={() => setEditShipping(true)} color={"primary"}>
                  <EditLocationAlt/>
               </IconButton>
            </>}>
            {editShipping &&
               <Dialog fullWidth={true} maxWidth={"md"} open={editShipping} onClose={() => setEditShipping(false)}>
                  <DialogContent>
                     <TitleText type={"h2"}>Edit shipping address</TitleText>
                     <AddressEditor address={address} onSave={onUpdateAddress}/>
                  </DialogContent>
               </Dialog>}
            <div>
               <TitleText type={"h1"}>Shipping address {modified && ' (modified)'}</TitleText>
               <BodyText type={"body1"}>
                  {address.name}
               </BodyText>
               <BodyText type={"body1"}>
                  {address?.company}
               </BodyText>
               <BodyText type={"body1"}>
                  {address.add1}
               </BodyText>
               <BodyText type={"body1"}>
                  {address.add2}
               </BodyText>
               <BodyText type={"body1"}>
                  {address.zipCode} {address.city}
               </BodyText>
               <BodyText type={"body1"}>
                  {address.country}
               </BodyText>
               <BodyText type={"body1"}>
                  {address.mail}
               </BodyText>
               <BodyText type={"body1"}>
                  {address.phone}
               </BodyText>
            </div>
         </PageContent>
      </PageGroup>
      {line && <OrderUpdateReason brandHouseId={brandHouseId} updateType={line.updateType} open={true} onUpdate={onReturnSelect}
                                  onClose={() => setLine(null)}/>}
      <ObjectHistory refId={order._id} secondary={order.lines?.map(line => ({
         refId: line!._id,
         title: 'Line'
      }))}/>
   </Page>
}

const useStyles = makeStyles({
   container: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignContent: 'stretch',
      alignItems: 'stretch'
   },
   firstCell: {
      width: '50px',
      minWidth: '50px',
   },
   imagecell: {
      width: '140px',
      minWidth: '140px',
   },
   maincell: {
      width: '50%'
   },
   actionPanel: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignContent: 'stretch',
      alignItems: 'stretch',
      marginLeft: '-22px',
      marginRight: '-22px',
      paddingTop: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      borderTop: '1px solid #eee'
   },
   dialogContent: {
      minHeight: '400px',
      height: '540px',
      minWidth: '100%'
   },
   editItem: {
      position: 'relative',
      top: '-5px',
      marginLeft: '10px'
   },
   registeredPanel: {
      backgroundColor: 'rgb(255, 244, 229)',
      padding: '20px'
   },
   button: {
      width: '150px',
      marginBottom: '2px'
   }
})


export function getTrackingUrl(trackingCarrier: string, trackingCode: string) {
   if (trackingCarrier === 'gls') {
      return 'https://gls-group.eu/EU/en/parcel-tracking?match=' + trackingCode
   } else if (trackingCarrier === 'pdk') {
      return 'https://tracking.postnord.com/?id=' + trackingCode
   } else if (trackingCarrier === 'dhl') {
      return 'https://www.dhl.com/global-en/home/tracking/tracking-express.html?submit=1&tracking-id=' + trackingCode
   } else if (trackingCarrier === 'dao') {
      return 'https://track.shipmondo.com/dao/' + trackingCode
   } else if (trackingCarrier === 'parcelplus' || trackingCarrier === 'b2c_europe') {
      return 'http://track.shipmondo.com/b2c_europe/' + trackingCode
   } else {
      return "https://track.shipmondo.com"
   }
}

