import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import Grid from "@mui/material/Grid";
import {useNavigate, useParams} from "react-router-dom";
import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Divider,
   FormControlLabel,
   LinearProgress,
   Switch
} from "@mui/material";
import {BodyText, Link, TitleText} from "../../../layout/Typography";
import {DateFormat} from "../../../utility/DateFormat";
import Button from "@mui/material/Button";
import {Theme} from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect} from "react";
import {ProductsList} from "../components/ProductList";
import {Dialogs} from "../../../DialogProvider";
import IconButton from "@mui/material/IconButton";
import CreateIcon from '@mui/icons-material/Create';
import useTradeInUpdate from "./hooks/useTradeInUpdate";
import {Condition} from "../components/Condition";
import {VASServiceDialog} from "../components/VASServiceDialog";
import useStockItemCreate from "./hooks/useStockItemCreate";
import {StockItemEditPagePath} from "../stockitems/edit/StockItemEditPage";
import {ConsumerPanel} from "../components/ConsumerPanel";
import {CurrentUserIsShop} from "../../../atoms/CurrentUser";
import {useRecoilValue} from "recoil";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useTradeInDelete from "./hooks/useTradeInDelete";
import {TradeInsPagePath} from "./TradeInsPage";
import {
   EnumTradeInItemRejected,
   ProductVariantSize,
   TradeInItem,
   TradeInItemRejectReasonEnum,
   useMainTradeInItemUpdateByIdMutation,
   useShopTradeInItemsRemoveMutation,
   useTradeInPageByIdQuery,
   useTradeInPageShopQuery,
   ViewProductVariantFragment
} from "../../../Queries";
import {Thumbnails} from "../../../components/Thumbnails";
import {getTradeInStatus} from "../components/TradeInList";
import {BrandTradeInPagePath} from "../../brand/tradeins/BrandTradeInPage";
import {VoucherPagePath} from "../../brand/vouchers/VoucherPage";

export const TradeInPagePath = (brandHouseId: string, shopId: string, tradeInId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/tradeins/" + tradeInId;
}
export const TradeInPage = () => {
   const {brandHouseId, shopId, tradeInId} = useParams<{ brandHouseId: string, shopId: string, tradeInId: string }>();
   const [tradeInItem, setTradeInItem] = React.useState<TradeInItem | undefined>();
   const [acceptItem, setAcceptItem] = React.useState<TradeInItem | undefined>();
   const [hideRegister, setHideRegister] = React.useState<boolean>(false);
   const navigate = useNavigate();
   const {data} = useTradeInPageByIdQuery({
      variables: {
         tradeInId: tradeInId
      }
   })
   const {data: shop} = useTradeInPageShopQuery({
      variables: {
         shopId: shopId
      }
   })

   const classes = useStyles();
   const [updateItem] = useMainTradeInItemUpdateByIdMutation()
   const updateTradeIn = useTradeInUpdate()
   const deleteTradeIn = useTradeInDelete()
   const createStockItem = useStockItemCreate()
   const [removeTradeInItem] = useShopTradeInItemsRemoveMutation()

   const isBrandLogin = useRecoilValue(CurrentUserIsShop)

   const tradeIn = data?.TradeInById;

   useEffect(() => {
      if (shop?.ShopById?.brandHouse?.settings?.useBrandItems) {
         navigate(BrandTradeInPagePath(brandHouseId, tradeInId), {
            replace: true
         })
      }
   }, [brandHouseId, navigate, shop, tradeInId])

   if (!tradeIn || !shop) {
      return <LinearProgress/>
   }

   const brand = shop.ShopById!

   const onChangeVariant = async (variant: ViewProductVariantFragment, size: Partial<ProductVariantSize>) => {
      Dialogs.confirm({
         title: 'Confirm change',
         subtitle: `Please confirm that you wish to change the tradein item to ${variant.sku}`
      }, async () => {
         await updateItem({
            variables: {
               id: tradeInItem!._id,
               record: {
                  name: variant.product?.name || undefined,
                  sku: variant.sku || undefined,
                  productVariantId: variant._id,
                  productVariantSizeId: size._id,
                  size: size?.size || undefined,
                  color: variant.color || undefined
               }
            },
            update(cache, {data}) {
               cache.evict(data?.TradeInItemUpdateById?.recordId);
            }
         });
         setTradeInItem(undefined);
      })
   }

   const onRegisterPackage = async (registered: boolean) => {
      await updateTradeIn(tradeInId, shopId, {
         registered: registered
      });
   }

   const onRejectItem = async (item: TradeInItem, reject: boolean) => {
      await updateItem({
         variables: {
            id: item!._id,
            record: {
               rejected: EnumTradeInItemRejected.OTHER
            }
         },
         update(cache, {data}) {
            cache.evict(data?.TradeInItemUpdateById?.recordId);
         }
      });
   }

   const onToggleReceived = async () => {
      setHideRegister(true);
      await onRegisterPackage(!tradeIn?.registered)
   }

   const onConditionChange = async (item: TradeInItem, condition: number | null) => {
      await updateItem({
         variables: {
            id: item!._id,
            record: {
               condition: condition || undefined
            }
         },
         update(cache, {data}) {
            cache.evict(data?.TradeInItemUpdateById?.recordId);
         }
      });
   }

   const onAccept = (item: TradeInItem) => () => {
      setAcceptItem(item);
   }
   const onViewStock = (item: TradeInItem) => () => {
      if (item.stockItem) {
         navigate(StockItemEditPagePath(brandHouseId, shopId, item.stockItem!._id))
      }
   }
   const onRemoveItem = (item: TradeInItem) => () => {
      Dialogs.confirm({
         title: 'Confirm removal of item?',
         subtitle: 'The item will be removed from this tradein, and the consumer will be allowed to trade it in again.\n' +
            'Please also notice:\n' +
            '- The issued voucher will be deleted.\n' +
            '- New voucher will be created for the remaining styles.\n' +
            '- This operation cannot be undone.\n' +
            'The consumer will receive a mail with the new voucher'
      }, async () => {
         await removeTradeInItem({
            variables: {
               tradeInId: tradeInId,
               tradeInItemsId: [String(item._id)],
               reject: TradeInItemRejectReasonEnum.OTHER
            }
         });
      })
   }

   const onDelete = () => {
      Dialogs.confirm({
         title: 'Are you sure you wish the delete this tradein?',
         subtitle: 'This cannot be undone. The traded in item will be released and the consumer will be allowed to trade it in again. Also the voucher for this tradein will be deleted'
      }, async () => {
         await deleteTradeIn(shopId, tradeInId);
         navigate(TradeInsPagePath(brandHouseId, shopId));
      })
   }

   const onAcceptComplete = (item: TradeInItem) => async () => {
      await createStockItem(shopId, item._id);
      setAcceptItem(undefined);
   }

   return <Page>
      <PageHeader title={"TradeIn " + tradeIn?.tradeInNr} onBack={true}>
         <Button disabled>
            <BodyText type={"subtitle1"}>Created at {DateFormat.toReadable(tradeIn?.dateTime)}</BodyText>
         </Button>
         <Button onClick={onDelete} startIcon={<DeleteForeverIcon/>}>Delete</Button>
      </PageHeader>

      <Grid container spacing={2}>
         <Grid item xs={8}>
            <PageContent>
               <TitleText type={"h2"}>Items {tradeIn?.items?.length}</TitleText>
               <Divider/>
               {tradeIn?.items?.map((titem, idx) => {
                  const item = titem as TradeInItem;

                  let color = item.color || item.productVariant?.color;
                  let size = item.size || item.productVariant?.size;
                  let sku = item.sku || item.productVariant?.sku;
                  size = (size === 'N/A' ? undefined : size)
                  color = (color === 'N/A' ? undefined : color)

                  return <div className={classes.container} key={'tradeinkey_' + item._id}>
                     <div className={classes.firstCell}>
                        #{idx + 1}
                     </div>
                     <div className={classes.imagecell}>
                        <Thumbnails imageUrls={item.imageUrls || []} size={120} maxImages={1}/>
                     </div>
                     <div className={classes.maincell}>
                        <BodyText type={"subtitle1"}>
                           {item.name || item.productVariant?.product?.name}
                           {!isBrandLogin &&
                              <IconButton title={"Correct the received product"} className={classes.editItem}
                                          color={"primary"} size={"small"} onClick={() => setTradeInItem(item as TradeInItem)}>
                                 <CreateIcon fontSize={"small"}/>
                              </IconButton>
                           }
                        </BodyText>
                        {color && <BodyText type={"body2"}>
                           Color: {color}
                        </BodyText>}
                        {size && <BodyText type={"body2"}>
                           Size: {size}
                        </BodyText>}
                        {sku && <BodyText type={"body2"}>
                           SKU: {sku}
                        </BodyText>}
                        {item.potential && <>
                           <BodyText type={"body2"}>
                              Recommended sales price: {item.potential?.rrp + ' ' + brand.currency}
                           </BodyText>
                           <BodyText type={"body2"}>
                              Actual sales price: {item.potential?.sp + ' ' + brand.currency}
                           </BodyText>
                        </>}
                        <br/>
                        {item.potential?.externUrl &&
                           <a href={item.potential.externUrl} target={"_blank"} rel={"noreferrer"}>Original order</a>
                        }
                     </div>
                     <div className={classes.ratingCell}>
                        <TitleText type={"subtitle1"}>Rate item condition</TitleText>
                        <Condition
                           readOnly={isBrandLogin}
                           conditions={(brand.conditions || []).map(cond => {
                              return {
                                 condition: cond!.condition || 0,
                                 name: cond!.name || 'Condition',
                                 description: cond!.description || ''
                              }
                           })}
                           current={item.condition || null}
                           onConditionChange={(condition) => onConditionChange(item as TradeInItem, condition)}
                        />
                     </div>
                     <div className={classes.lastCell}>
                        {!isBrandLogin && <>
                           {item.rejected ?
                              <Button onClick={() => onRejectItem(item as TradeInItem, false)}
                                      disabled={!item.condition}
                                      className={classes.button}
                                      variant={"outlined"}>Undo rejection</Button>
                              : <>
                                 {!item.stockItem &&
                                    <Button onClick={onAccept(item as TradeInItem)} disabled={!item.condition || !tradeIn?.registered}
                                            className={classes.button}
                                            variant={"outlined"}>Accept</Button>
                                 }
                                 {!item.stockItem &&
                                    <Button onClick={() => onRejectItem(item as TradeInItem, true)}
                                            className={classes.button}
                                            variant={"outlined"}>Reject</Button>
                                 }
                              </>
                           }
                        </>}
                        {item.stockItem &&
                           <Button onClick={onViewStock(item as TradeInItem)} className={classes.button}
                                   variant={"outlined"}>View
                              in stock</Button>
                        }
                        {!item.stockItem &&
                           <Button
                              disabled={tradeIn?.items.length === 1}
                              onClick={onRemoveItem(item as TradeInItem)} className={classes.button} variant={"outlined"}>
                              Remove</Button>
                        }
                     </div>
                  </div>
               })}
            </PageContent>
         </Grid>
         <Grid item xs={4}>
            <PageContent>
               <ConsumerPanel consumerId={tradeIn!.consumerId}/>

               <div style={{height: '30px'}}/>

               {(tradeIn?.vouchers && tradeIn?.vouchers.length > 0) && <>
                  <TitleText type={"h1"}>Voucher</TitleText>
                  {tradeIn?.vouchers?.map(voucher => {
                     return <div key={'voucherkey_' + voucher?.code}>
                        <div><Link
                           onClick={() => navigate(VoucherPagePath(brandHouseId, shopId, voucher?._id))}>{voucher?.code}</Link>
                        </div>
                        <div>{voucher?.value + " " + voucher?.currency + " (Active: " + voucher?.activated + ")"}</div>
                     </div>
                  })}
                  <div style={{height: '30px'}}/>
               </>}

               {(tradeIn?.circularPoints && tradeIn?.circularPoints.length > 0) && <>
                  <TitleText type={"h1"}>Circular points</TitleText>
                  {tradeIn?.circularPoints?.map(point => {
                     return <div key={'voucherkey_' + point?._id}>
                        <div>{point?.value + " (Active: " + point?.activated + ")"}</div>
                     </div>
                  })}
                  <div style={{height: '30px'}}/>
               </>}

               <TitleText type={"h1"}>Label</TitleText>
               <BodyText type={"body1"}>Status: {getTradeInStatus(tradeIn?.registered, tradeIn?.shippingCarrierId, tradeIn?.label?.deliveryDate, tradeIn?.label?.deliveryStatus)}</BodyText>
               <a rel={"noreferrer"} target={"_blank"} href={tradeIn?.label?.url || '#'}>Download shipping label</a>
               <div style={{height: '30px'}}/>

               <TitleText type={"h1"}>Received</TitleText>
               <FormControlLabel
                  control={<Switch size="small" color={"primary"} checked={!!tradeIn?.registered}
                                   onChange={onToggleReceived}/>}
                  label={tradeIn?.registered ? DateFormat.toPresent(tradeIn?.registeredTs) : "Not yet"}
               />
               {tradeIn?.registered && !tradeIn?.notified && <div>Mail will be sent soon</div>}
               {tradeIn?.registered && tradeIn?.notified && <div>Mail was sent</div>}

            </PageContent>
         </Grid>
      </Grid>
      <VASServiceDialog open={!!acceptItem} item={acceptItem?.name || acceptItem?.sku || 'Unnamed'}
                        onSave={onAcceptComplete(acceptItem!)} onCancel={() => setAcceptItem(undefined)}/>
      <Dialog fullWidth={true} maxWidth={"lg"} open={!!tradeInItem} onClose={() => setTradeInItem(undefined)}>
         <DialogTitle>
            <TitleText type={"h1"}>Correct the type of product</TitleText>
            <BodyText type={"subtitle1"}>Use to correct consumers tradein products</BodyText>
         </DialogTitle>
         <DialogContent className={classes.dialogContent}>
            <ProductsList shopId={shopId} onVariantSelect={onChangeVariant} inDialog/>
         </DialogContent>
         <DialogActions>
            <Button variant={"outlined"} onClick={() => setTradeInItem(undefined)}>Hide</Button>
         </DialogActions>
      </Dialog>
      <Dialog open={!isBrandLogin && !tradeIn?.registered && !hideRegister} onClose={() => setHideRegister(true)}>
         <DialogTitle>
            TradeIn Package Received?
         </DialogTitle>
         <DialogContent>
            <BodyText type={"body1"}>
               Press "Yes" to send mail that we received the {tradeIn?.items.length} item(s).
            </BodyText>
         </DialogContent>
         <DialogActions>
            <Button color={"primary"} variant={"contained"} onClick={() => onRegisterPackage(true)}>YES</Button>
            <Button variant={"contained"} onClick={() => setHideRegister(true)}>NO</Button>
         </DialogActions>
      </Dialog>
   </Page>
}


const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      container: {
         marginTop: '20px',
         display: 'flex',
         justifyContent: 'flex-start',
         alignContent: 'stretch',
         alignItems: 'stretch'
      },
      firstCell: {
         width: '50px',
         minWidth: '50px',
      },
      imagecell: {
         width: '140px',
         minWidth: '140px',
      },
      maincell: {
         width: '100%'
      },
      ratingCell: {
         width: '50%'
      },
      lastCell: {
         minWidth: '150px',
         textAlign: 'right'
      },
      dialogContent: {
         height: '650px',
         minWidth: '100%'
      },
      editItem: {
         position: 'relative',
         top: '-5px',
         marginLeft: '10px'
      },
      registeredPanel: {
         backgroundColor: 'rgb(255, 244, 229)',
         padding: '20px'
      },
      button: {
         width: '150px',
         marginBottom: '2px'
      }
   }),
);
