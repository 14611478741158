import React from 'react';
import { Controller } from 'react-hook-form';
import { FileDrop } from 'react-file-drop';
import { createUploadService } from '../../../../UploadService';

interface FormInputImageUploadProps {
   name: string;
   control: any;
   container: string;
   label?: string;
}

interface ThumbnailProps {
   size?: number;
   image?: string;
   style?: React.CSSProperties;
   onClick?: () => void;
}

const Thumbnail: React.FC<ThumbnailProps> = ({ size = 46, image, style, onClick }) => (
   <div
      onClick={onClick}
      style={{
         width: size,
         height: size,
         backgroundImage: `url("${image || 'https://storageaccountourimbea2.blob.core.windows.net/web/placeholder-image.png'}")`,
         backgroundSize: 'cover',
         backgroundPosition: 'center',
         backgroundRepeat: 'no-repeat',
         cursor: 'pointer',
         borderRadius: '4px',
         ...style
      }}
   />
);

export const FormInputImageUpload: React.FC<FormInputImageUploadProps> = ({
                                                                             name,
                                                                             control,
                                                                             container,
                                                                             label
                                                                          }) => {
   const uploadService = createUploadService(process.env.REACT_APP_FILESERVICEURL!, container);
   const fileInputRef = React.useRef<HTMLInputElement>(null);

   const handleUpload = async (files: FileList | null, onChange: (value: string) => void) => {
      if (!files || files.length === 0) return;

      const file = files[0];
      try {
         const response = await uploadService.upload(file);
         if (response?.success) {
            const imageUrl = `${uploadService.url()}/${response.imageId}`;
            onChange(imageUrl);
         }
      } catch (error) {
         console.error('Upload failed:', error);
      }
   };

   return (
      <Controller
         name={name}
         control={control}
         render={({ field: { onChange, value } }) => {
            const handleClick = () => fileInputRef.current?.click();

            return (
               <div>
                  <FileDrop
                     onDrop={(files) => handleUpload(files, onChange)}
                     dropEffect="copy"
                  >
                     <Thumbnail
                        style={{ marginTop: '-5px' }}
                        size={46}
                        image={value}
                        onClick={handleClick}
                     />
                  </FileDrop>

                  <input
                     ref={fileInputRef}
                     type="file"
                     style={{ display: 'none' }}
                     onChange={(e) => handleUpload(e.target.files, onChange)}
                     accept="image/*"
                  />
               </div>
            );
         }}
      />
   );
};
