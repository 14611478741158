import {Box, Button, IconButton} from "@mui/material";
import {useState} from "react";
import {useFieldArray, useFormContext} from "react-hook-form";
import {TitleText} from "../../../../layout/Typography";
import {MasterEditorData} from "../MasterDataEditor";
import {Thumbnail} from "../../../../components/Thumbnail";
import {FormInputSelect} from "../../../shop/wholesale/form/FormInputSelect";
import {EnumBrandProductImagePerspective, EnumBrandProductImageType} from "../../../../Queries";
import DeleteIcon from "@mui/icons-material/Delete";
import {FormInputImageUpload} from "../../../shop/wholesale/form/FormInputImageUpload";

type ImageEditorProps = {
   brandHouseId: string
   variant: number
}

export const ImageEditor = ({brandHouseId, variant}: ImageEditorProps) => {
   const [showAll, setShowAll] = useState(false);
   const formContext = useFormContext<MasterEditorData>();
   const {control} = formContext;

   const {fields: images, append: imageAppend, remove: imageRemove} = useFieldArray({
      control,
      name: `variants.${variant}.images`
   });

   const displayImages = showAll ? images : images.slice(0, 4);
   const hasMoreImages = images.length > 4;

   return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
         <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <TitleText type="h2">Images</TitleText>
         </Box>

         <Box sx={{width: '100%'}}>
            {displayImages.map((image, i) => (
               <Box
                  key={`image_${variant}_${i}`}
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     gap: 1,
                  }}
               >
                  <Box sx={{width: 46, height: 46,}}>
                     {image._id && <Thumbnail style={{marginTop: '-5px'}} size={46} image={image.url}/>}
                     {!image._id &&
                        <FormInputImageUpload name={`variants.${variant}.images.${i}.url`} control={control} label={"Image"} container={brandHouseId}/>}
                  </Box>
                  <Box sx={{flex: 1}}>
                     <FormInputSelect
                        name={`variants.${variant}.images.${i}.perspective`}
                        label="Perspective"
                        options={Object.values(EnumBrandProductImagePerspective).map(value => ({
                           id: value,
                           name: value
                        }))}
                     />
                  </Box>
                  <Box sx={{flex: 1}}>
                     <FormInputSelect
                        name={`variants.${variant}.images.${i}.type`}
                        label="Type"
                        options={Object.values(EnumBrandProductImageType).map(value => ({
                           id: value,
                           name: value
                        }))}
                     />
                  </Box>
                  <IconButton
                     size="small"
                     onClick={() => imageRemove(i)}
                     sx={{alignSelf: 'flex-start', mt: 1}}
                  >
                     <DeleteIcon/>
                  </IconButton>
               </Box>
            ))}

            {hasMoreImages && (
               <Button
                  color={"secondary"}
                  onClick={() => setShowAll(!showAll)}
                  size="small"
               >
                  {showAll ? 'Show less' : `Show ${images.length - 4} more images`}
               </Button>
            )}

            <Button
               size="small"
               onClick={() => {
                  imageAppend({
                     _id: '',
                     externalId: '',
                     deleted: false,
                     url: '',
                     perspective: EnumBrandProductImagePerspective.FRONT,
                     type: EnumBrandProductImageType.OTHER
                  });
               }}>Add image</Button>
         </Box>
      </Box>
   );
};