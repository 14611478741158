import * as React from "react";
import {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Theme, useMediaQuery} from "@mui/material";
import clsx from "clsx";
import TopBar from "./layout/TopBar";
import Sidebar from "./layout/SideBar";

import {Route, Routes} from "react-router-dom";
import {HomePage} from "./pages/home/HomePage";
import {useRecoilValue} from "recoil";
import {Hierarchy} from "./Hierarchy";
import {CurrentUserIsShop, CurrentUserState} from "./atoms/CurrentUser";
import {BrandHousePage} from "./pages/brandhouse/BrandHousePage";
import {ShopsPage} from "./pages/brandhouse/ShopsPage";
import {BrandPage} from "./pages/brand/BrandPage";
import {WholeSalePage} from "./pages/shop/wholesale/WholeSalePage";
import {DTCPage} from "./pages/shop/dtc/main/DTCPage";
import {DTCViewPage} from "./pages/shop/dtc/view/DTCViewPage";
import {DTCEditPage} from "./pages/shop/dtc/edit/DTCEditPage";
import {TradeInsPage} from "./pages/shop/tradeins/TradeInsPage";
import {ProductsPage} from "./pages/shop/shopdata/products/ProductsPage";
import {ProductDetailPage} from "./pages/shop/shopdata/products/ProductDetailPage";
import {TradeInPage} from "./pages/shop/tradeins/TradeInPage";
import {ConsumersPage} from "./pages/shop/shopdata/consumers/ConsumersPage";
import {StockItemsPage} from "./pages/shop/stockitems/StockItemsPage";
import {StockItemEditPage} from "./pages/shop/stockitems/edit/StockItemEditPage";
import {OrdersPage} from "./pages/shop/orders/OrdersPage";
import {ConsumerPage} from "./pages/shop/shopdata/consumers/ConsumerPage";
import {WholeSaleEditPage} from "./pages/shop/wholesale/WholeSaleEditPage";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {PotentialsPage} from "./pages/shop/potentials/PotentialsPage";
import {IntegrationsSettingsPage} from "./pages/shop/settings/IntegrationsSettingsPage";
import {OrderPage} from "./pages/shop/orders/OrderPage";
import {AnalyticsPage} from "./pages/brand/AnalyticsPage";
import {TradeInAppTextsV1, TradeInAppTextsV2} from "./pages/shop/app/apptexts/TradeInAppTexts";
import {ShippingCarrierEdit} from "./pages/admin/shippingcarriers/ShippingCarrierEdit";
import {ShippingCarriers} from "./pages/admin/shippingcarriers/ShippingCarriers";
import {LogAnalyticsPage} from "./pages/logistics/analytics/LogAnalyticsPage";
import {DashboardPage} from "./pages/brand/DashboardPage";
import {NFCPage} from "./pages/logistics/nfc/NFCPage";
import {InventoryPage} from "./pages/logistics/inventory/InventoryPage";
import {LogisticsTrackingPage} from "./pages/logistics/LogisticsTrackingPage";
import {LogisticsOrdersPage} from "./pages/logistics/LogisticsOrdersPage";
import {InvoicesPage} from "./pages/brandhouse/invoices/InvoicesPage";
import {LogisticsStockItemsPage} from "./pages/logistics/LogisticsStockItemsPage";
import {BrandSettingsPage} from "./pages/settings/BrandSettingsPage";
import {LogisticsInvoicesPage} from "./pages/logistics/LogisticsInvoicesPage";
import {InviteAcceptPage} from "./pages/account/InviteAcceptPage";
import {QRSession} from "./pages/logistics/qr-app/QRSession";
import {QrApp} from "./pages/logistics/qr-app/QrApp";
import {BrandItemsPageWrapper} from "./pages/brand/BrandItemsPage";
import {BrandItemCreateEmptyPage, BrandItemCreatePage, BrandItemPage} from "./pages/brand/brandItem/BrandItemPage";
import {BrandTradeInsListPage} from "./pages/brand/tradeins/BrandTradeInsListPage";
import {LogisticsTradeInsListPage} from "./pages/logistics/tradeins/LogisticsTradeInsListPage";
import {TrackingPage} from "./pages/shop/tracking/TrackingPage";
import {BrandTrackingPage} from "./pages/brand/BrandTrackingPage";
import {BrandOrdersPage} from "./pages/brand/BrandOrdersPage";
import {LogisticsKPIPage} from "./pages/logistics/LogisticsKPIPage";
import {BrandTradeInPage} from "./pages/brand/tradeins/BrandTradeInPage";
import {ConnectorsPage} from "./pages/admin/ConnectorsPage";
import {ConnectorCreatePage, ConnectorEditPage} from "./pages/admin/ConnectorEditPage";
import {SeasonsPage} from "./pages/brand/seasons/SeasonsPage";
import {SeasonsEditPage} from "./pages/brand/seasons/SeasonsEditPage";
import {LogisticsBrandItemsPageWrapper, LogisticsItemPage} from "./pages/logistics/LogisticsBrandItemsPage";
import {LogisticsIntegrationsPage} from "./pages/logistics/LogisticsIntegrationsPage";
import {LogisticsIntegrationPage} from "./pages/logistics/LogisticsIntegrationPage";
import {MessagesPage} from "./pages/admin/MessagesPage";
import {ConsumerEventsPage} from "./pages/shop/tradeins/ConsumerEventsPage";
import {ParcelsPage} from "./pages/shop/parcels/ParcelsPage";
import {AuditPage} from "./pages/admin/audit/AuditPage";
import {VouchersPage} from "./pages/brand/vouchers/VouchersPage";
import {VoucherPage} from "./pages/brand/vouchers/VoucherPage";
import {ShopDataPage} from "./pages/shop/shopdata/ShopDataPage";
import { MasterDataPage } from "./pages/brand/masterdata/MasterDataPage";
import {MasterDataEditPage} from "./pages/brand/masterdata/MasterDataEditPage";

const useStyles = makeStyles((theme: Theme) => ({
   root: {
      height: 'calc(100vh - 50px)',
      paddingRight: '15px'
   },
   rootUser: {
      paddingTop: 48,
   },
   topBar: {
      backgroundColor: 'white'
   },
   anchorBrandHouse: {
      position: 'fixed',
      right: 0,
      top: 0,
      bottom: 0,
      paddingTop: '20px',
      paddingLeft: '4px',
      paddingRight: '2px',
      backgroundColor: 'white',
      borderLeft: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0',
      cursor: 'pointer'
   },
   shiftContentRight: {
      paddingRight: 320
   },
   shiftContentLeft: {
      paddingLeft: 320
   },
   content: {
      height: '100%'
   },
   flexGrow: {
      flexGrow: 1
   },
   signOutButton: {
      marginLeft: theme.spacing(1)
   },
   treeRoot: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
   }
}));

export const isInsideIframe = () => {
   try {
      return window.self !== window.top;
   } catch (e) {
      return true;
   }
}


const MainWindow = () => {
   const currentUser = useRecoilValue(CurrentUserState);
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);

   // Responsiveness
   const classes = useStyles();
   const isDesktop = useMediaQuery('(min-width:1200px)');

   // Dynamic page stuff
   const [openSidebar, setOpenSidebar] = useState(false);
   const handleSidebarOpen = () => {
      setOpenSidebar(true);
   };
   const handleSidebarClose = () => {
      setOpenSidebar(false);
   };

   const shouldOpenSidebar = isDesktop ? true : openSidebar;

   const isInsideIFrame = isInsideIframe();

   const showTopBar = !isBrandHouse || !isInsideIFrame;

   return (
      <div
         className={clsx({
            [classes.root]: true,
            [classes.rootUser]: showTopBar,
            [classes.shiftContentLeft]: (showTopBar && shouldOpenSidebar),
            [classes.shiftContentRight]: (!showTopBar && shouldOpenSidebar)
         })}
      >
         {!currentUser?.hideFrame && <>
            {showTopBar &&
               <TopBar onSidebarOpen={handleSidebarOpen} anchor={showTopBar ? "left" : "right"}/>
            }
            {!showTopBar &&
               <div onClick={handleSidebarOpen} className={classes.anchorBrandHouse}><AccountBalanceIcon
                  htmlColor={"#c0c0c0"}
                  fontSize={"small"}/>
               </div>
            }
            <Sidebar onClose={handleSidebarClose}
                     open={shouldOpenSidebar}
                     variant={isDesktop ? 'persistent' : 'temporary'}
                     anchor={showTopBar ? "left" : "right"}
                     topbarHidden={!showTopBar}
            >
               <Hierarchy/>
            </Sidebar>
         </>}
         <main>
            <div className={classes.content}>
               <Routes>
                  <Route path={"/"} element={<HomePage/>}/>
                  <Route path={"/account/accept/:joinId"} element={<InviteAcceptPage/>}/>
                  <Route path={"/account/accept"} element={<InviteAcceptPage/>}/>
                  <Route path={"/admin/connectors/create"} element={<ConnectorCreatePage/>}/>
                  <Route path={"/admin/connectors/:connectorId"} element={<ConnectorEditPage/>}/>
                  <Route path={"/admin/connectors"} element={<ConnectorsPage/>}/>
                  <Route path={"/admin/messages"} element={<MessagesPage/>}/>
                  <Route path={"/admin/shippingcarriers"} element={<ShippingCarriers/>}/>
                  <Route path={"/admin/shippingcarriers/:shippingCarrierId"}
                         element={<ShippingCarrierEdit/>}/>
                  <Route path={"/admin/tracking"} element={<LogisticsTrackingPage/>}/>
                  <Route path={"/admin/audit"} element={<AuditPage/>}/>
                  <Route path={"/qrsession"} element={<QRSession/>}/>
                  <Route path={"/logisticspartners/:logisticsPartnerId"} element={<LogAnalyticsPage/>}/>
                  <Route path={"/logisticspartners/:logisticsPartnerId/app"} element={<QrApp/>}/>
                  <Route path={"/logisticspartners/:logisticsPartnerId/alltradeins"}
                         element={<LogisticsTradeInsListPage/>}/>
                  <Route path={"/logisticspartners/:logisticsPartnerId/orders"}
                         element={<LogisticsOrdersPage/>}/>
                  <Route path={"/logisticspartners/:logisticsPartnerId/stockitems"}
                         element={<LogisticsStockItemsPage/>}/>
                  <Route path={"/logisticspartners/:logisticsPartnerId/branditems"}
                         element={<LogisticsBrandItemsPageWrapper/>}>
                     <Route path={":brandItemId"} element={<LogisticsItemPage/>}/>
                  </Route>
                  <Route path={"/logisticspartners/:logisticsPartnerId/invoices"}
                         element={<LogisticsInvoicesPage/>}/>
                  <Route path={"logisticspartners/:logisticsPartnerId/nfctags"}
                         element={<NFCPage/>}/>
                  <Route path={"logisticspartners/:logisticsPartnerId/inventory"}
                         element={<InventoryPage/>}/>
                  <Route path={"logisticspartners/:logisticsPartnerId/integrations/:instanceId"}
                         element={<LogisticsIntegrationPage/>}/>
                  <Route path={"logisticspartners/:logisticsPartnerId/integrations"}
                         element={<LogisticsIntegrationsPage/>}/>
                  <Route path={"logisticspartners/:logisticsPartnerId/kpis"} element={<LogisticsKPIPage/>}/>
                  <Route path={"brands"} element={<BrandHousePage/>}/>
                  <Route path={"brands/:brandHouseId"} element={<ShopsPage/>}/>
                  <Route path={"brands/:brandHouseId/settings"} element={<BrandSettingsPage/>}/>
                  <Route path={"brands/:brandHouseId/settings/*"} element={<BrandSettingsPage/>}/>
                  <Route path={"brands/:brandHouseId/invoices"} element={<InvoicesPage/>}/>
                  <Route path={"brands/:brandHouseId/dashboard"} element={<DashboardPage/>}/>
                  <Route path={"brands/:brandHouseId/tradeins/:tradeInId"} element={<BrandTradeInPage/>}/>
                  <Route path={"brands/:brandHouseId/tradeins"} element={<BrandTradeInsListPage/>}/>
                  <Route path={"brands/:brandHouseId/tracking"} element={<BrandTrackingPage/>}/>
                  <Route path={"brands/:brandHouseId/orders"} element={<BrandOrdersPage/>}/>
                  <Route path={"brands/:brandHouseId/seasons/:seasonId"} element={<SeasonsEditPage/>}/>
                  <Route path={"brands/:brandHouseId/seasons"} element={<SeasonsPage/>}/>
                  <Route path={"brands/:brandHouseId/masterdata/create/:groupId"} element={<MasterDataEditPage/>}/>
                  <Route path={"brands/:brandHouseId/masterdata/:editId"} element={<MasterDataEditPage/>}/>
                  <Route path={"brands/:brandHouseId/masterdata"} element={<MasterDataPage/>}/>
                  <Route path={"brands/:brandHouseId/stock/create/:brandProductSizeId"}
                         element={<BrandItemCreatePage/>}/>
                  <Route path={"brands/:brandHouseId/stock/create"} element={<BrandItemCreateEmptyPage/>}/>
                  <Route path={"brands/:brandHouseId/stock"} element={<BrandItemsPageWrapper/>}>
                     <Route path={":brandItemId"} element={<BrandItemPage inDrawer={true}/>}/>
                  </Route>
                  <Route path={"brands/:brandHouseId/stock/edit/:brandItemId"}
                         element={<BrandItemPage inDrawer={false}/>}/>

                  <Route path={"brands/:brandHouseId/rewards"}
                         element={<VouchersPage/>}/>
                  <Route path={"brands/:brandHouseId/rewards/:voucherId"}
                         element={<VoucherPage/>}/>

                  <Route path={"brands/:brandHouseId/:shopId"} element={<BrandPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/analytics"} element={<AnalyticsPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/shop"} element={<ConsumerEventsPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/tracking"} element={<TrackingPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/parcels"} element={<ParcelsPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/localizations"}
                         element={<TradeInAppTextsV1/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/localizations2"}
                         element={<TradeInAppTextsV2/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/wholesale"}
                         element={<WholeSalePage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/dtc"}
                         element={<DTCPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/integrations"}
                         element={<IntegrationsSettingsPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/dtc/:brandRuleId"}
                         element={<DTCViewPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/dtc/:brandRuleId/edit"}
                         element={<DTCEditPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/wholesale/:categoryId"}
                         element={<WholeSaleEditPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/data"}
                         element={<ShopDataPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/products"}
                         element={<ProductsPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/products/:productId"}
                         element={<ProductDetailPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/tradeins"}
                         element={<TradeInsPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/tradeins/:tradeInId"}
                         element={<TradeInPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/stock"}
                         element={<StockItemsPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/stock/:stockItemId/edit"}
                         element={<StockItemEditPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/orders"}
                         element={<OrdersPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/orders/:orderId"}
                         element={<OrderPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/potentials"}
                         element={<PotentialsPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/consumers"}
                         element={<ConsumersPage/>}/>
                  <Route path={"brands/:brandHouseId/:shopId/consumers/:consumerId"}
                         element={<ConsumerPage/>}/>
               </Routes>
            </div>
         </main>
      </div>
   );
};

export default MainWindow;

