import {Dialog, DialogTitle, Grid, Card, CardContent, Typography, Box, TextField, Button} from '@mui/material';
import { CameraAlt, Build, LocalLaundryService, Delete, Favorite, MoreHoriz } from '@mui/icons-material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BrandEditorItem } from './BrandItemEditor';
import { useState } from 'react';

interface RequestActionProps {
   open: boolean;
   onClose: () => void;
}

const actions = [
   { title: 'Retake photos', icon: CameraAlt, description: 'Request new photos of the item' },
   { title: 'Request Repair', icon: Build, description: 'Item needs repair or maintenance' },
   { title: 'Request Wash', icon: LocalLaundryService, description: 'Item needs cleaning' },
   { title: 'Discard item', icon: Delete, description: 'Item cannot be sold or used' },
   { title: 'Donate item', icon: Favorite, description: 'Donate item to charity' },
   { title: 'Other', icon: MoreHoriz, description: 'Other action required' }
];

export const BrandItemRequestAction = ({ open, onClose }: RequestActionProps) => {
   const { control } = useFormContext<BrandEditorItem>();
   const { append } = useFieldArray({
      control,
      name: "problems",
   });
   const [selectedAction, setSelectedAction] = useState<string | null>(null);
   const [additionalDetails, setAdditionalDetails] = useState('');

   const handleActionSelect = (title: string) => {
      setSelectedAction(title);
   };

   const handleSubmit = () => {
      if (!selectedAction) return;

      const description = additionalDetails.trim()
         ? `${selectedAction}: ${additionalDetails.trim()}`
         : selectedAction;

      append({
         description,
         solved: false,
         createdAt: new Date()
      });

      handleClose();
   };

   const handleClose = () => {
      setSelectedAction(null);
      setAdditionalDetails('');
      onClose();
   };

   return (
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
         <DialogTitle>Request Action</DialogTitle>
         <Grid container spacing={2} sx={{ p: 2 }}>
            {actions.map((action) => (
               <Grid item xs={12} sm={6} key={action.title}>
                  <Card
                     sx={{
                        cursor: 'pointer',
                        backgroundColor: selectedAction === action.title ? 'action.selected' : 'background.paper',
                        transition: 'background-color 0.2s',
                        '&:hover': {
                           backgroundColor: selectedAction === action.title
                              ? 'action.selected'
                              : 'action.hover'
                        }
                     }}
                     onClick={() => handleActionSelect(action.title)}
                  >
                     <CardContent sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                     }}>
                        <action.icon color="primary" />
                        <div>
                           <Typography variant="h6" component="div">
                              {action.title}
                           </Typography>
                           <Typography variant="body2" color="text.secondary">
                              {action.description}
                           </Typography>
                        </div>
                     </CardContent>
                  </Card>
               </Grid>
            ))}

            <Grid item xs={12}>
               <Box sx={{ mt: 2 }}>
                  <TextField
                     fullWidth
                     label="Additional details (optional)"
                     value={additionalDetails}
                     onChange={(e) => setAdditionalDetails(e.target.value)}
                     multiline
                     rows={2}
                     placeholder="Add any specific details about your request..."
                  />
               </Box>
            </Grid>

            <Grid item xs={12}>
               <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                     variant="contained"
                     onClick={handleSubmit}
                     disabled={!selectedAction}
                  >
                     Submit Request
                  </Button>
               </Box>
            </Grid>
         </Grid>
      </Dialog>
   );
};