import {useParams} from "react-router-dom";
import {ParamBrandHouse} from "../../../Parameters";
import PageHeader from "../../../layout/PageHeader";
import * as React from "react";
import Page from "../../../layout/Page";
import {
   BrandProductFragment,
   EnumBrandProductImagePerspective,
   EnumBrandProductImageType,
   MasterDataProductDocument,
   useMasterDataProductQuery,
   useMasterDataProductSettingsQuery,
   useMasterDataSynchronizeMutation
} from "../../../Queries";
import {MasterDataEditor, MasterEditorData, MasterInfoData, MasterItemState} from "./MasterDataEditor";
import {LinearProgress} from "@mui/material";
import {toLocalizedField} from "../../components/LocalizedUtility";
import {DateFormat} from "../../../utility/DateFormat";
import {toCurrencies} from "../../components/CurrencyUtility";
import Button from "@mui/material/Button";
import {convertToSyncInput} from "./DataConversion";
import {SavingUI} from "../../../SavingProvider";
import {useState} from "react";

export const MasterDataEditorPath = (brandHouseId: string, editId: string) => {
   return "/brands/" + brandHouseId + "/masterdata/" + editId
}

export const MasterDataEditorCreatePath = (brandHouseId: string, groupId: string) => {
   return "/brands/" + brandHouseId + "/masterdata/create/" + groupId
}

export const MasterDataEditPage = () => {
   const [formKey, setFormKey] = useState(0);

   const {brandHouseId, editId, groupId} = useParams<ParamBrandHouse & {
      editId: string
      groupId: string
   }>()

   const {data: product, refetch} = useMasterDataProductQuery({
      variables: {
         id: editId
      },
      skip: !editId
   });
   const {data: settings} = useMasterDataProductSettingsQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   })

   const [updateProduct] = useMasterDataSynchronizeMutation({
      refetchQueries: [{
         query: MasterDataProductDocument,
         variables: {id: editId},
         fetchPolicy: 'network-only'
      }]
   });

   if ((editId && !product) || !settings) {
      return <LinearProgress/>
   }

   const locales = (settings.BrandSettings?.locales || []).filter(l => l?.activeItems && l?.locale).map(l => l!.locale);

   const currencies = (settings.BrandSettings?.stockItemConfig?.currencies || []).filter(c => c?.active && c?.currency).map(c => c!.currency!);

   const convertToMasterEditorData = (product?: BrandProductFragment | null): MasterEditorData => {
      // Create empty localized fields for each locale
      const emptyLocalizedFields = locales.map(locale => ({
         locale,
         value: ''
      }));

      // Create empty price entries for each currency
      const emptyPrices = currencies.map(currency => ({
         currency,
         value: 0
      }));

      return {
         _id: product?._id || '',
         disableRRPListPrice: product?.disableRRPListPrice || false,
         groupId: groupId || product?.groupId || '',
         externalId: product?.externalId || '',
         name: product ? toLocalizedField({locales, data: product.nameLocalized}) : emptyLocalizedFields,
         description: product ? toLocalizedField({locales, data: product.descriptionLocalized}) : emptyLocalizedFields,
         type: product ? toLocalizedField({locales, data: product.typeLocalized}) : emptyLocalizedFields,
         vendor: product?.vendor || '',

         state: product?.deleted ? MasterItemState.DELETED :
            product?.disabled ? MasterItemState.DEACTIVATED :
               MasterItemState.ACTIVE,

         tags: (product?.tags || []).map(t => ({value: t!})),
         collections: (product?.collections || []).map(t => ({value: t!})),
         variants: product?.variants ? product.variants.map(v => ({
            _id: v?._id || '',
            externalId: v?.externalId || '',
            sku: v?.sku || '',
            deactivated: v?.deactivated || false,
            color: v ? toLocalizedField({locales, data: v.colorLocalized}) : emptyLocalizedFields,
            countryOrigin: v?.countryOrigin || '',
            commodityCode: v?.commodityCode || '',
            sizes: (v?.sizes || []).map(s => ({
               _id: s?._id || '',
               externalId: s?.externalId || '',
               ean: s?.ean || '',
               sku: s?.sku || '',
               deleted: s?.deleted || false,
               size: s?.sizeLocalized?.find(l => l?.value)?.value || '',
            })),
            images: (v?.images || []).map(i => ({
               _id: i?._id || '',
               externalId: i?.externalId || '',
               deleted: i?.deleted || false,
               url: i?.imageUrl || '',
               perspective: i?.perspective || EnumBrandProductImagePerspective.OTHER,
               type: i?.type || EnumBrandProductImageType.OTHER
            })),
            prices: v?.rrp ? toCurrencies({currencies, data: v.rrp}) : emptyPrices,
         })) : [],
      };
   };

   const masterInfo: MasterInfoData = {
      createdAt: DateFormat.toReadable(product?.BrandProduct?.createdAt),
      updatedAt: DateFormat.toReadable(product?.BrandProduct?.updatedAt || 'Unknown'),
   }

   const onSave = async (data: MasterEditorData) => {
      if (!settings?.BrandSettings) return;

      const syncPayload = convertToSyncInput(data, locales);
      await SavingUI.process(async () => {
         await updateProduct({
            variables: {
               brandHouseId: brandHouseId,
               input: syncPayload
            }
         });
      }, 'Saved', 'Saving, please wait');

      // Refetch the data to ensure we have the latest
      await refetch();

      // Force form reset by updating the key
      setFormKey(prev => prev + 1);
   }

   return <Page>
      <PageHeader title={"Master Data"} onBack={true}>
         <Button variant={"contained"} type={"submit"} form={"masterDataForm"}>
            Save
         </Button>
      </PageHeader>
      <MasterDataEditor
         key={formKey}
         brandHouseId={brandHouseId}
         data={convertToMasterEditorData(product?.BrandProduct)}
         onSave={onSave} locales={locales} info={masterInfo} currencies={currencies}/>
   </Page>
}

