import React, {useState} from "react";
import Page from "../../layout/Page";
import {
   EnumIntegrationConnectorMessages,
   EnumIntegrationConnectorRefType,
   EnumIntegrationConnectorType,
   EnumShopFrontendType,
   IntegrationFragment,
   useConnectorEditPageCreateMutation,
   useConnectorEditPageQuery,
   useConnectorEditPageUpdateMutation
} from "../../Queries";
import {useParams} from "react-router";
import {FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import PageContent from "../../layout/PageContent";
import {FormInputText} from "../shop/wholesale/form/FormInputText";
import {Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@mui/material";
import {FormInputSelect} from "../shop/wholesale/form/FormInputSelect";
import {FormInputAdd} from "../../components/FormInputAdd";
import {TitleText} from "../../layout/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {AddCircle} from "@mui/icons-material";
import {Dialogs} from "../../DialogProvider";
import {SavingUI} from "../../SavingProvider";
import {useNavigate} from "react-router-dom";
import PageContentTabs, {PageTab} from "../../layout/PageContentTabs";
import {InstallsPage} from "./InstallsPage";

const ID_CONNECTORFORM = "ConnectorForm";

export const ConnectorEditPagePath = (connectorId: string) => {
   return "/admin/connectors/" + connectorId
}

export const ConnectorEditPage = () => {
   const {connectorId} = useParams<{ connectorId: string }>()

   const {data} = useConnectorEditPageQuery({
      variables: {
         connectorId
      }
   })
   if (!data) {
      return <LinearProgress/>
   }
   const editData = (data?.IntegrationConnector || {}) as IntegrationFragment
   return <ConnectorEdit editData={editData}/>
}

export const ConnectorCreatePagePath = () => {
   return "/admin/connectors/create"
}

export const ConnectorCreatePage = () => {
   return <ConnectorEdit editData={{
      name: "New connector",
      type: EnumIntegrationConnectorType.EVENTBUS,
      messages: [EnumIntegrationConnectorMessages.INIT, EnumIntegrationConnectorMessages.UPDATECONFIG]
   } as IntegrationFragment}/>
}

type ConnectorEditProps = {
   editData: IntegrationFragment
}
const ConnectorEdit = ({editData}: ConnectorEditProps) => {
   const [update] = useConnectorEditPageUpdateMutation()
   const [create] = useConnectorEditPageCreateMutation()
   const navigate = useNavigate();

   const toStringValueArray = (arr?: (string | null)[] | undefined | null) => {
      return arr?.map(a => {
         return {
            value: a!
         }
      }) || []
   }

   const toStringArray = (arr?: StringValue[]) => {
      return arr?.map(a => a.value) || [];
   }

   const onSave = (data: ConnectorEditFormData) => {
      Dialogs.confirm({
         title: 'Confirm updating integration',
         subtitle: 'Warning, updating the integration settings can be the cause of integration crashes'
      }, async () => {
         await SavingUI.process(async () => {
            const dataToUpdate: any = {
               name: data.name,
               autoInstallFor: toStringArray(data.autoInstallFor),
               messages: toStringArray(data.messages) as EnumIntegrationConnectorMessages[],
               optionals: toStringArray(data.optionals) as EnumIntegrationConnectorMessages[],
               type: data.type,
               refTypes: toStringArray(data.refTypes) as EnumIntegrationConnectorRefType[],
               fields: data.fields?.map(field => ({
                  field: field.field,
                  type: field.type,
                  description: field.description,
                  namespace: field.namespace,
                  options: field.options,
                  defaultValue: field.defaultValue
               })),
               eventbus: data.eventbus ? {
                  production: {
                     serviceBus: data.eventbus.production!.serviceBus!,
                     topic: data.eventbus.production!.topic!
                  },
                  development: {
                     serviceBus: data.eventbus.development!.serviceBus!,
                     topic: data.eventbus.development!.topic!
                  },
                  test: {
                     serviceBus: data.eventbus.test!.serviceBus!,
                     topic: data.eventbus.test!.topic!
                  }
               } : undefined
            };

            if (data._id) {
               await update({
                  variables: {
                     connectorId: data._id,
                     input: dataToUpdate
                  }
               })
            } else {
               const {data: newConnector} = await create({
                  variables: {
                     input: dataToUpdate
                  }
               });
               navigate(ConnectorEditPagePath(newConnector?.IntegrationConnectorCreate?.recordId), {
                  replace: true
               })
            }
         }, 'Integration updated')
      })
   }

   const TAB_INSTALLED = 'Installed for';
   const TAB_EDIT = 'Connector';
   const tabs: PageTab[] = [{
      label: TAB_EDIT
   }, {
      label: TAB_INSTALLED
   }]

   return <Page>
      <PageContentTabs onBack tabs={tabs} render={(tab) => {
         if (tab === TAB_INSTALLED) {
            return <InstallsPage connectorId={editData._id}/>
         } else {
            return <PageContent>
               <ConnectorEditForm
                  onSave={onSave}
                  data={{
                     _id: editData._id,
                     refTypes: toStringValueArray(editData.refTypes),
                     name: editData.name,
                     activeFor: toStringValueArray(editData.activeFor),
                     autoInstallFor: toStringValueArray(editData.autoInstallFor),
                     messages: toStringValueArray(editData.messages),
                     optionals: toStringValueArray(editData.optionals),
                     fields: (editData.fields || []).map(field => ({
                        namespace: field!.namespace,
                        description: field!.description,
                        field: field!.field,
                        type: field!.type,
                        defaultValue: field!.defaultValue!,
                        options: (field!.options || []).map(opt => ({
                           value: opt!.value!,
                           label: opt!.label!
                        }))
                     })),
                     type: editData.type!,
                     eventbus: editData?.eventbus ? {
                        production: {
                           serviceBus: editData?.eventbus?.production?.serviceBus,
                           topic: editData?.eventbus?.production?.topic
                        },
                        development: {
                           serviceBus: editData?.eventbus?.development?.serviceBus,
                           topic: editData?.eventbus?.development?.topic
                        },
                        test: {
                           serviceBus: editData?.eventbus?.test?.serviceBus,
                           topic: editData?.eventbus?.test?.topic
                        }
                     } : undefined
                  }}/>
            </PageContent>
         }
      }} commands={[<Button key={"connectorSave"} type={"submit"} form={ID_CONNECTORFORM} variant={"contained"}>Save</Button>]}>
      </PageContentTabs>
   </Page>
}

type StringValue = {
   value: string
};
type FormEventBus = {
   serviceBus?: string
   topic?: string
};
type FormField = {
   namespace: string
   field: string
   description: string
   type: string
   options: FormOption[]
   defaultValue: string
}

type FormOption = {
   value: any
   label: string
}
type FormWebhook = {
   id: string
   url: string
}

type ConnectorEditFormData = {
   _id: any,
   name: string,
   refTypes?: StringValue[],
   deleted?: boolean,
   activeFor?: StringValue[],
   autoInstallFor?: StringValue[],
   messages: StringValue[],
   optionals: StringValue[],
   type: EnumIntegrationConnectorType
   eventbus?: {
      development?: FormEventBus,
      production?: FormEventBus,
      test?: FormEventBus
   },
   fields?: FormField[],
   webhook?: {
      test?: FormWebhook,
      production?: FormWebhook,
      development?: FormWebhook
   }
}

type ConnectorEditFormProps = {
   data: ConnectorEditFormData
   onSave: (data: ConnectorEditFormData) => void
}

const ConnectorEditForm = ({data, onSave}: ConnectorEditFormProps) => {
   const [showOptions, setShowOptions] = useState<number>(-1)
   const form = useForm<ConnectorEditFormData>({
      defaultValues: data
   });

   const {control, handleSubmit} = form

   const {fields: messages, append: addMessage, remove: delMessage} = useFieldArray<ConnectorEditFormData>({
      control,
      name: "messages"
   });

   const {fields: optionals, append: addOptional, remove: delOptional} = useFieldArray<ConnectorEditFormData>({
      control,
      name: "optionals"
   });

   const {fields: autoInstalls, append: addAutoInstall, remove: delAutoInstall} = useFieldArray<ConnectorEditFormData>({
      control,
      name: "autoInstallFor"
   });

   const {fields, append: addField, remove: delField} = useFieldArray<ConnectorEditFormData>({
      control,
      name: "fields"
   });

   const {fields: refTypes, append: addRefType, remove: delRefType} = useFieldArray<ConnectorEditFormData>({
      control,
      name: "refTypes"
   });

   const onSubmit = (data: ConnectorEditFormData) => {
      onSave(data);
   }

   return <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} id={ID_CONNECTORFORM} style={{width: '100%'}}>
         <FormInputText name={"name"} label={"Name of connector"}/>
         <TitleText type={"h2"}>Install available for:</TitleText>
         <Box sx={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
            {refTypes.map((install, i) => {
               const value = (install as any).value
               return <Chip key={install.id} label={value} onDelete={() => {
                  delRefType(i)
               }}/>
            })}
            <FormInputAdd onSelect={(msg) => addRefType({value: msg})}
                          values={Object.values(EnumIntegrationConnectorRefType)}
                          label={"Add target"}
                          id={"refType"}
                          hideValues={refTypes.map(msg => (msg as any).value)}/>
         </Box>
         <TitleText type={"h2"}>Supported events</TitleText>
         <Box sx={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
            {messages.map((msg, i) => {
               const value = (msg as any).value
               return <Chip key={msg.id} label={value} onDelete={() => {
                  delMessage(i)
               }}/>
            })}
            <FormInputAdd onSelect={(msg) => addMessage({value: msg})}
                          values={Object.values(EnumIntegrationConnectorMessages)}
                          label={"Add event"}
                          id={"events"}
                          hideValues={messages.map(msg => (msg as any).value)}/>
         </Box>
         <TitleText type={"h2"}>Optional events</TitleText>
         <Box sx={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
            {optionals.map((msg, i) => {
               const value = (msg as any).value
               return <Chip variant={"outlined"} key={msg.id} label={value} onDelete={() => {
                  delOptional(i)
               }}/>
            })}
            <FormInputAdd onSelect={(msg) => addOptional({value: msg})}
                          values={Object.values(EnumIntegrationConnectorMessages).filter(msg => messages.some((m:any) => m.value === msg))}
                          label={"Add optional"}
                          id={"optional"}
                          hideValues={optionals.map(msg => (msg as any).value)}/>
         </Box>
         <TitleText type={"h2"}>Auto installed for:</TitleText>
         <Box sx={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
            {autoInstalls.map((install, i) => {
               const value = (install as any).value
               return <Chip key={install.id} label={value} onDelete={() => {
                  delAutoInstall(i)
               }}/>
            })}
            <FormInputAdd onSelect={(msg) => addAutoInstall({value: msg})}
                          values={Object.values(EnumShopFrontendType)}
                          label={"Add frontend"}
                          id={"installFor"}
                          hideValues={autoInstalls.map(msg => (msg as any).value)}/>
         </Box>
         <TitleText type={"h2"}>Configuration fieldset:</TitleText>
         {fields.map((field, i) => {
            const ffield = field as any
            return <Box key={field.id} sx={{display: 'flex', gap: '4px'}}>
               <FormInputText name={`fields[${i}].field`} label={"Field name (spaces not allowed)"}/>
               <FormInputText name={`fields[${i}].namespace`} label={"Namespace (spaces not allowed)"}/>
               <FormInputText name={`fields[${i}].description`} label={"Description"}/>
               <FormInputSelect name={`fields[${i}].type`} label={"Data type"} options={[{
                  id: "text",
                  name: "Text"
               }, {
                  id: "textarea",
                  name: "TextArea"
               }, {
                  id: "json",
                  name: "JSON"
               }, {
                  id: "number",
                  name: "Number"
               }, {
                  id: "checkbox",
                  name: "Checkbox"
               }, {
                  id: "password",
                  name: "Secret / Password"
               }, {
                  id: "color",
                  name: "Color picker"
               }]}/>
               <Box sx={{display: 'flex', margin: '0px 0px 8px 0px'}}>
                  <Button variant={"outlined"} color={"secondary"} onClick={() => setShowOptions(i)}>
                     <FormatListNumberedIcon/>
                  </Button>
               </Box>
               <FormInputText name={`fields[${i}].defaultValue`} label={"Default value"}/>
               <Button>
                  <DeleteIcon onClick={() => delField(i)}/>
               </Button>
               {showOptions === i && <Dialog open={true} onClose={() => setShowOptions(-1)} fullWidth maxWidth={"md"}>
                  <DialogTitle>
                     <TitleText type={"h2"}>Define selection of values for {ffield.field}:</TitleText>
                  </DialogTitle>
                  <DialogContent>
                     <FormOptionsDialog index={i}/>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={() => setShowOptions(-1)} variant={"contained"}>Hide</Button>
                  </DialogActions>
               </Dialog>}
            </Box>
         })}
         <Box>
            <Button
               variant={"outlined"}
               color={"secondary"}
               startIcon={<AddCircle/>}
               onClick={() => addField({
                  namespace: '',
                  field: '',
                  description: '',
                  type: 'text',
                  options: []
               })}
            >Add fieldset
            </Button>
         </Box>
         <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <TitleText type={"h2"}>Eventbus communication</TitleText>
            <FormInputSelect name={"type"} label={"Communications type"}
                             options={Object.values(EnumIntegrationConnectorType).map(connectType => ({
                                id: connectType,
                                name: connectType
                             }))}/>
            <EventBusEditor name={"production"}/>
            <EventBusEditor name={"development"}/>
            <EventBusEditor name={"test"}/>
         </Box>
      </form>
   </FormProvider>
}

type FormOptionsDialogProps = {
   index: number
}

const FormOptionsDialog = ({index}: FormOptionsDialogProps) => {
   const form = useFormContext<ConnectorEditFormData>()
   const {control} = form;

   const {fields, append, remove} = useFieldArray<ConnectorEditFormData>({
      control,
      name: `fields.${index}.options`
   })

   return <Box sx={{minHeight: '200px'}}>
      {fields.map((field, i) => {
         return <Box key={field.id} sx={{display: 'flex', gap: '4px'}}>
            <FormInputText name={`fields.${index}.options[${i}].value`} label={"Value when selected"}/>
            <FormInputText name={`fields.${index}.options[${i}].label`} label={"Label"}/>
            <Button onClick={() => {
               remove(i)
            }}>
               <DeleteIcon/>
            </Button>
         </Box>
      })}
      <Button onClick={() => append({
         value: '',
         label: ''
      })}>Add option</Button>
   </Box>
}

type EventBusEditorProps = {
   name: string
}

const EventBusEditor = ({name}: EventBusEditorProps) => {
   return <Box sx={{display: 'flex', flexDirection: 'row', gap: '4px', alignItems: "center"}}>
      <Box sx={{
         flex: '1 1 0',

      }}>
         <TitleText type={"h3"}>{name?.toUpperCase()}</TitleText>
      </Box>
      <Box sx={{
         flex: '4 1 0'
      }}>
         <FormInputText name={"eventbus." + name + ".serviceBus"} label={"Service bus (connection string)"}/>
      </Box>
      <Box sx={{
         flex: '1 1 0'
      }}>
         <FormInputText name={"eventbus." + name + ".topic"} label={"Topic"}/>
      </Box>
   </Box>
}
