import * as React from "react";
import {useEffect} from "react";
import {LinearProgress, SvgIconProps} from "@mui/material";
import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import AppsIcon from "@mui/icons-material/Apps";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {NavigationStateController} from "./NavigationStateController";
import StorageIcon from '@mui/icons-material/Storage';
import CloudIcon from '@mui/icons-material/Cloud';
import Cloud from '@mui/icons-material/Cloud';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EventIcon from '@mui/icons-material/Event';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PhoneIphone from "@mui/icons-material/PhoneIphone";
import {CurrentUserIsAdmin, CurrentUserState, LoggingOut, saveToStorage} from "../atoms/CurrentUser";
import FeaturedPlayList from "@mui/icons-material/FeaturedPlayList";
import {
   Assessment,
   IntegrationInstructions,
   LocalShippingOutlined,
   MonitorHeart,
   SettingsEthernet,
   ShowChart,
   VerifiedUser,
   WbSunny
} from "@mui/icons-material";
import NfcIcon from '@mui/icons-material/Nfc';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {useNavigationDataQuery} from "../Queries";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SettingsIcon from "@mui/icons-material/Settings";
import {PubSub} from "../components/PubSub";
import {StringHelper} from "../utility/StringHelper";
import useLocalStorage from "../hooks/useLocalStorage";
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

export type NavigationItem = {
   id: string
   title: string
   icon: React.ElementType<SvgIconProps>
   path: string
   children?: NavigationItem[]
   topbar?: NavigationLeaf[]
   pathMatch?: boolean
   hide?: boolean
   hideScope?: 'all' | 'shops',
   action?: NavigationLeaf
   disabledNotice?: string
}

export type NavigationLeaf = {
   id: string
   title: string
   icon: React.ElementType<SvgIconProps>
   path: string
}

export type NavigationData = {
   items: NavigationItem[]
}

export const NavigationDataAtom = atom<NavigationData | null>({
   key: 'navigationData',
   default: null
})

type NavigationControllerProps = {
   shopId?: string
}

export const NavigationController = (props: React.PropsWithChildren<NavigationControllerProps>) => {
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);
   const {data, refetch} = useNavigationDataQuery({
      fetchPolicy: 'no-cache'
   })
   const setNavigationData = useSetRecoilState(NavigationDataAtom);
   const navigationData = useRecoilValue(NavigationDataAtom);
   const [loggingOut, setLoggingOut] = useRecoilState(LoggingOut);
   const setCurrentUser = useSetRecoilState(CurrentUserState);
   const [rootId, setRootId] = useLocalStorage<string | null>('brandRootId', null)

   useEffect(() => {
      if (data?.BrandHouses || data?.CurrentUser || data?.LogisticsPartners) {
         let brandHouses = data.BrandHouses || [];
         if (rootId) {
            brandHouses = brandHouses.filter(bh => bh._id === rootId)
         }

         let logisticsPartners = data.LogisticsPartners || [];
         let brandHouseItems = brandHouses
            .sort((a, b) => StringHelper.compare(a?.name, b?.name))
            .map(brandHouse => {
               const hideForBestseller = String(brandHouse._id) === '63d636acde3ad47a5ce87c3b' && !isAdministrator

               let shops = brandHouse?.shops || [];
               let brandItems: NavigationItem[] = shops
                  .sort((a, b) => StringHelper.compare(a?.name, b?.name))
                  .map(shopObj => {
                     const shop = shopObj!
                     let shopId = String(shop!._id);
                     return {
                        id: shopId,
                        title: shop.name,
                        disabledNotice: (shop.disabled || !shop.live) ? '(Offline)' : undefined,
                        icon: StorefrontIcon,
                        path: "/brands/" + brandHouse._id + "/" + shop._id,
                        childrenintopbar: true,
                        hide: hideForBestseller,
                        children: [{
                           id: shopId + '_analytics',
                           title: 'Analytics',
                           icon: Assessment,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/analytics",
                           hide: true
                        }, {
                           id: shopId + '_viewtradeins',
                           title: 'View TradeIns',
                           icon: DoubleArrowIcon,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/viewtradeins",
                           hide: true,
                           pathMatch: true
                        }, {
                           id: shopId + '_tradeins',
                           title: 'TradeIns',
                           icon: DoubleArrowIcon,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/tradeins",
                           pathMatch: true,
                           children: [{
                              id: shopId + '_dtc',
                              title: 'Direct To Consumer',
                              icon: MarkunreadIcon,
                              path: "/brands/" + brandHouse._id + "/" + shop._id + "/dtc",
                              pathMatch: true
                           }, {
                              id: shopId + '_wholesale',
                              title: 'Categories',
                              icon: LocalConvenienceStoreIcon,
                              path: "/brands/" + brandHouse._id + "/" + shop._id + "/wholesale",
                              pathMatch: true,
                           }, {
                              id: shopId + '_config',
                              title: 'Events',
                              icon: EventIcon,
                              path: "/brands/" + brandHouse._id + "/" + shop._id + "/shop",
                           }]
                        }, {
                           id: shopId + '_stock',
                           title: 'Stock',
                           icon: StorageIcon,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/stock",
                           pathMatch: true,
                           hide: !!brandHouse.settings?.useBrandItems
                        }, {
                           id: shopId + '_orders',
                           title: 'Orders',
                           icon: PictureInPictureIcon,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/orders",
                           pathMatch: true,
                           hide: hideForBestseller
                        }, {
                           id: shopId + '_consumers',
                           title: 'Consumers',
                           icon: PersonIcon,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/consumers",
                           pathMatch: true,
                           hide: hideForBestseller
                        }, {
                           id: shopId + '_backend',
                           title: 'Master data',
                           icon: CloudIcon,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/data",
                           children: [{
                              id: shopId + '_products',
                              title: 'Products',
                              icon: CloudQueueIcon,
                              path: "/brands/" + brandHouse._id + "/" + shop._id + "/products",
                              pathMatch: true,
                           }, {
                              id: shopId + '_potentials',
                              title: 'Orders',
                              icon: CloudQueueIcon,
                              path: "/brands/" + brandHouse._id + "/" + shop._id + "/potentials",
                              pathMatch: true,
                           }],
                           hide: true
                        }, {
                           id: shopId + '_integrations',
                           title: 'Integrations',
                           icon: SettingsApplicationsIcon,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/integrations",
                           hide: true
                        }, {
                           id: shopId + '_tracking',
                           title: 'Activity',
                           icon: MonitorHeart,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/tracking",
                           hide: hideForBestseller,
                           hideScope: hideForBestseller ? 'all' : 'none',
                        }, {
                           id: shopId + '_parcels',
                           title: 'Parcels',
                           icon: LocalShippingOutlined,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/parcels",
                           hide: hideForBestseller,
                           hideScope: hideForBestseller ? 'all' : 'none',
                        }, {
                           id: shopId + '_edit',
                           title: 'Edit',
                           icon: SettingsApplicationsIcon,
                           path: "/brands/" + brandHouse._id + "/" + shop._id + "/edit",
                           hide: true,
                           hideScope: 'all'
                        }]
                     }
                  }) as NavigationItem[] || [];
               return {
                  id: String(brandHouse?._id),
                  title: brandHouse?.name,
                  icon: AccountBalanceIcon,
                  path: "/brands/" + brandHouse?._id,
                  children: [{
                     id: 'invoices',
                     icon: ReceiptIcon,
                     path: "/brands/" + brandHouse?._id + "/invoices",
                     title: "Invoices",
                     hide: true
                  }, {
                     id: 'dashboard',
                     title: 'Dashboard',
                     icon: Assessment,
                     path: "/brands/" + brandHouse._id + "/dashboard",
                     hide: false,
                  }, {
                     id: 'brandtradeins',
                     title: 'Trade Ins',
                     icon: DoubleArrowIcon,
                     path: "/brands/" + brandHouse._id + "/tradeins",
                     hide: false,
                     pathMatch: true
                  }, {
                     id: 'branditems',
                     title: 'Stock Items',
                     icon: StorageIcon,
                     path: "/brands/" + brandHouse._id + "/stock",
                     hide: false,
                     pathMatch: true
                  }, {
                     id: 'brandorders',
                     title: 'Orders',
                     icon: PictureInPictureIcon,
                     path: "/brands/" + brandHouse._id + "/orders",
                     hide: false
                  }, {
                     id: 'brandvouchers',
                     title: 'Rewards',
                     icon: CardGiftcardIcon,
                     path: "/brands/" + brandHouse._id + "/rewards",
                     pathMatch: true
                  }, {
                     id: 'brandseasons',
                     title: 'Seasons',
                     icon: WbSunny,
                     path: "/brands/" + brandHouse._id + "/seasons",
                     pathMatch: true,
                     hide: hideForBestseller,
                     hideScope: hideForBestseller ? 'all' : undefined
                  }, {
                     id: 'brandmasterdata',
                     title: 'Master Data',
                     icon: Cloud,
                     path: "/brands/" + brandHouse._id + "/masterdata",
                     pathMatch: true,
                     hide: hideForBestseller,
                     hideScope: hideForBestseller ? 'all' : undefined
                  }, {
                     id: 'brandtracking',
                     title: 'Activity',
                     icon: MonitorHeart,
                     path: "/brands/" + brandHouse._id + "/tracking",
                     hide: hideForBestseller,
                     hideScope: hideForBestseller ? 'all' : undefined,
                     pathMatch: true
                  }, ...brandItems] as NavigationItem[],
                  action: {
                     id: 'brandSettings',
                     title: 'Settings',
                     icon: SettingsIcon,
                     path: "/brands/" + brandHouse._id + "/settings"
                  }
               } as NavigationItem
            });
         let logisticsPartnerRoot = {
            id: "logisticspartners",
            title: "Logistics Partners",
            icon: DeviceHubIcon,
            path: "/logisticspartners",
            children: logisticsPartners.map(lp => {
               return {
                  id: String(lp?._id),
                  title: lp?.name,
                  icon: StorefrontIcon,
                  path: "/logisticspartners/" + lp?._id,
                  childrenintopbar: true,
                  children: [{
                     id: "alltradeins",
                     icon: DoubleArrowIcon,
                     path: "/logisticspartners/" + lp?._id + "/alltradeins",
                     title: "TradeIns"
                  }, {
                     id: "allorders",
                     icon: PictureInPictureIcon,
                     path: "/logisticspartners/" + lp?._id + "/orders",
                     title: "Orders",
                     pathMatch: true
                  }, {
                     id: "allinvoices",
                     icon: ReceiptIcon,
                     path: "/logisticspartners/" + lp?._id + "/invoices",
                     title: "Invoices",
                     pathMatch: true
                  }, {
                     id: "nfctags",
                     icon: NfcIcon,
                     path: "/logisticspartners/" + lp?._id + "/nfctags",
                     title: "NFC Tags",
                     pathMatch: true
                  }, {
                     id: "allbranditems",
                     icon: StorageIcon,
                     path: "/logisticspartners/" + lp?._id + "/branditems",
                     title: "Brand Items",
                     pathMatch: true
                  }, {
                     id: 'inventory',
                     icon: FeaturedPlayList,
                     path: "/logisticspartners/" + lp?._id + "/inventory",
                     title: "Inventory"
                  }, {
                     id: 'kpis',
                     icon: ShowChart,
                     path: "/logisticspartners/" + lp?._id + "/kpis",
                     title: "KPIS"
                  }, {
                     id: "integrations",
                     icon: IntegrationInstructionsIcon,
                     path: "/logisticspartners/" + lp?._id + "/integrations",
                     title: "Integrations",
                     pathMatch: true
                  }, {
                     id: "app",
                     icon: PhoneIphone,
                     path: "/logisticspartners/" + lp?._id + "/app",
                     title: "App"
                  }]
               }
            })
         };

         let brandHouseRoot;
         if (brandHouseItems?.length === 1 && !isAdministrator) {
            brandHouseRoot = brandHouseItems[0]
         } else {
            brandHouseRoot = {
               id: "brandhouses",
               title: "Brands",
               icon: FolderOpenIcon,
               path: "/brands",
               children: brandHouseItems
            };
         }
         const globalItems: NavigationItem[] = isAdministrator ? [{
            id: "root",
            title: "Admin",
            icon: AppsIcon,
            path: "/",
            children: [{
               id: '_connectors',
               title: 'Connectors',
               icon: IntegrationInstructions,
               path: "/admin/connectors",
               hide: true,
               pathMatch: true
            }, {
               id: '_messages',
               title: 'Messages',
               icon: SettingsEthernet,
               path: "/admin/messages",
               hide: true,
               pathMatch: true
            }, {
               id: "shippingcarriers",
               icon: LocalShippingIcon,
               path: "/admin/shippingcarriers",
               title: "Shipping Carriers",
               pathMatch: true
            }, {
               id: 'tracking',
               icon: MonitorHeart,
               path: "/admin/tracking",
               title: "Activity"
            }, {
               id: 'graphql',
               icon: VerifiedUser,
               path: "/admin/graphql",
               title: "GraphQL Playground"
            }]
         }, {
            id: "qrsession",
            title: "QRSession",
            icon: AppsIcon,
            path: "/qrsession",
            hide: true,
            hideScope: "all"
         }] : [];
         if (logisticsPartners?.length > 0) {
            globalItems.push(logisticsPartnerRoot as NavigationItem);
         }
         if ((brandHouses && brandHouses.length > 0) || isAdministrator) {
            globalItems.push(brandHouseRoot as NavigationItem);
         }
         setNavigationData({
            items: globalItems
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [rootId, data])

   useEffect(() => {
      PubSub.on('NavigationReload', async (rootId) => {
         if (rootId !== undefined) {
            setRootId(rootId)
         }
         await refetch()
      })
      // eslint-disable-next-line
   }, [refetch])

   useEffect(() => {
      if (loggingOut) {
         setRootId(null);
         saveToStorage(null);
         setCurrentUser(null);
         (async function () {
            await refetch();
            setLoggingOut(false);
         })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [loggingOut])

   if (!data || !navigationData || loggingOut) {
      return <LinearProgress/>
   }

   return <NavigationStateController>
      {props.children}
   </NavigationStateController>
}
